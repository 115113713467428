import { useFeathers } from '../util'
import { useState, useCallback, useEffect } from 'react'
import { asyncPoolAll } from '../util';

export const useFindIntersectingDistrictsFull = (districtFull, additionalQuery = {}) => {
  const [districts, setDistricts] = useState([])
  const feathers = useFeathers();
  const numChunks = Math.ceil(districtFull?.intersectsWith?.length / 20) || 0;
  const chunkedIntersectsWith = Array.from({ length: numChunks }, (_, i) => districtFull.intersectsWith.slice(i * 20, (i + 1) * 20)).filter(s => s)

  const findDistricts = async () => {
    const results = await asyncPoolAll(10, chunkedIntersectsWith, async (chunk) => {
      const query = { _id: chunk, ...additionalQuery, $limit: 20}
      const { data } =  await feathers.getService('districts').find({ query })
      return data
    })

    setDistricts(results.flat())
  };

  useEffect(() => {
    if (!feathers || !districtFull) return;
    findDistricts()
  }, [districtFull]);

  return { districts }
};