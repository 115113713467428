import { Button } from "@material-ui/core";
import { useFeathers } from "../../app/util";
import * as XLSX from 'xlsx';
import moment from 'moment';
import superagent from 'superagent';

const DownloadProcessBetaSpreadsheet = ({
	tier2Districts,
	election
}) => {
	const feathers = useFeathers();

	const downloadSpreadsheet = async () => {
		const response = await superagent.get('https://districts-microservice.herokuapp.com/api/v1/district-configurations/' + election?.stateCode?.toLowerCase());
		const configurationForState = response.body;

		const tier2Types = configurationForState.types.state;
		const tier2TypesWithChildren = tier2Types.filter(type => configurationForState.types[type]?.length > 0);


		const wb = XLSX.utils.book_new();
		/* generate a spreadsheet for each of these types */
		for (let i = 0; i < tier2TypesWithChildren.length; i++) {
			const tier2Type = tier2TypesWithChildren[i];
			const tier2DistrictsForType = tier2Districts.filter(district => district.type === tier2Type);
			let headers = [
				{ headerName: 'Parent Type', field: 'type'},
				{ headerName: 'Name', field: 'name'},
			]

			const tier3Types = configurationForState.types[tier2Type];
			tier3Types.forEach(tier3Type => {
				const nameOfType = tier3Type.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
				headers.push({ headerName: `# of ${nameOfType}` });
				headers.push({ headerName: `${nameOfType} names (comma-seperated, only if non-numeric)` });
				headers.push({ headerName: `Are any of the districts voted on at-large?` });
				headers.push({ headerName: `${nameOfType} notes`})
			})

			headers.push({ headerName: `Special Attention (only fill out if something does not fit neatly into the structure here and explain why, otherwise please leave blank)`})
			headers.push({ headerName: `Date completed`})

			const data = tier2DistrictsForType.map(district => {
				return headers.map(header => header.field ? (district[header.field] ?? '') : '')
			})
			const dataWithHeaders = [headers.map(header => header.headerName), ...data];

			const tier2TypeWritten = tier2Type.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
	
			const ws = XLSX.utils.aoa_to_sheet(dataWithHeaders);
			ws["!cols"] = headers.map(header => ({ wch: 40 }));
			XLSX.utils.book_append_sheet(wb, ws, tier2TypeWritten);
		}
		
		/* generate XLSX file and download to client */
		XLSX.writeFile(wb, `Process Beta ${election?.key} ${moment().format('LLL')}.xlsx`);
	}

	return (
		<Button onClick={downloadSpreadsheet} variant="contained" size='small' color='primary'>Download spreadsheet</Button>
	)
}

export { DownloadProcessBetaSpreadsheet };