import React, { useEffect, useState, useMemo } from 'react';
import {isPermitted, useFeathers } from '../../app/util';
import { useSelector } from 'react-redux';
import {
  useParams,
  Link,
  useHistory
} from 'react-router-dom';

import {
  LoadingSpinner,
  ResearcherLevelBadge,
  TaskTile,
  FeedbackPreviewTile,
  NotificationBadge,
} from '../../components';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { taskConfigurations } from '../../app/util';
const taskTypes = Object.keys(taskConfigurations)

const Home = () => {
  const feathers = useFeathers();
  const history = useHistory();
  const user = useSelector(state => state.user);
  const permsForTasks = useMemo(() => {
    return Object.keys(taskConfigurations).reduce((acc, taskType) => {
      acc[taskType] = isPermitted(user, taskConfigurations[taskType].permissions);
      return acc;
    }, {})
  }, [ user, taskConfigurations ])
  const [ taskCounts, setTaskCounts ] = useState({});

  const [ loading, setLoading ] = useState(false);
  const [ loadingFeedback, setLoadingFeedback ] = useState(false);
  const [ feedbacks, setFeedbacks ] = useState([]);

  const displayName = user?.firstName || user?.name;

  const loadTaskCounts = async () => {
    // for each task type (find-website, review-candidate, etc), loads the total number of tasks that need to be completed
    setLoading(true);
    try {
      await Promise.all(taskTypes.map(async (taskType) => {
        if(!permsForTasks[taskType]) return;
        const taskSpecificAdditionalQuery = taskConfigurations[taskType]?.additionalTaskQuery ? taskConfigurations[taskType].additionalTaskQuery(user) : {};
        const taskCount = await feathers.getService('research-tasks').find({ query: { type: taskType, ...taskSpecificAdditionalQuery, $limit: 0  }});
        setTaskCounts((prev) => ({ ...prev, [taskType]: taskCount.total }))
      }))
    } catch(err) {
      console.log('Error loading', err)
    } finally {
      setLoading(false);
    }
  }

  const loadFeedback = async () => {
    if(loadingFeedback) return;
    // loads feedback for the user
    setLoadingFeedback(true);

    try {
      const feedback = await feathers.getService('profile-feedbacks').find({ 
        query: { 
          intendedFor: user._id, 
          $includeCandidate: true,
          $sort: { createdAt: -1 } 
        }
      });
      setFeedbacks(feedback.data)
    } catch(err) {
      console.error(err)
    } finally {
      setLoadingFeedback(false);
    }
  }


  useEffect(() => {
    if(feathers) {
      loadTaskCounts()
      loadFeedback()
    }
  }, [ feathers ])

  if(loading) {
    return (
      <Wrapper>
        <LoadingTile>
          <LoadingSpinner scale={0.8}/>
        </LoadingTile>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '16px', marginBottom: '24px', }}>
          <Typography style={{ marginBottom: '-6px' }} variant={'h1'}>Welcome{displayName ? `, ${displayName}` : ''}!</Typography>
          {
            user?.researcherLevel && <ResearcherLevelBadge level={user.researcherLevel} />
          }
        </div>
        <Typography style={{ }} variant={'body1'}>It's a good day to change the world.<br/><br/>Every day people get more disenchanted by politics. We're helping them understand all the impact their vote has at the state and local level.</Typography>
      </div>
      <Section>
        <Typography style={{ marginBottom: '24px', }} variant={'h4'}>Do some research</Typography>
        <TileWrapper>
          <TileWrapperInner>
            {
              Object.keys(taskConfigurations).map((taskType, index) => {
                if(!permsForTasks[taskType]) return null;
                return <TaskTile key={index} style={{ background: taskConfigurations[taskType].color }} task={taskConfigurations[taskType].title} icon={taskConfigurations[taskType].icon} onClick={() => history.push(`/tasks/${taskType}`)} count={taskCounts[taskType]} disabled={loading}/>
              })
            }
            <div style={{ minWidth: '20px', minHeight: '2px'}} />
          </TileWrapperInner>
        </TileWrapper>
      </Section>
      <Section>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '8px', marginBottom: '24px' }}>
          <Typography style={{  }} variant={'h4'}>Improve your skills</Typography>
          {feedbacks?.some(f => !f.acknowledged) && 
            <NotificationBadge count={feedbacks.filter(f => !f.acknowledged).length} />
          }
        </div>
        <TileWrapper>
          <TileWrapperInner>
            {
              feedbacks.map((feedback, index) => {
                return <FeedbackPreviewTile key={index} feedback={feedback} />
              })
            }
            <div style={{ minWidth: '20px', minHeight: '2px'}} />
          </TileWrapperInner>
        </TileWrapper>
      </Section>
      <Section>
        <Typography style={{ marginBottom: '24px', }} variant={'h4'}>Track your progress</Typography>
        <Link to={`/team/analytics/${user?._id}`}>
          <Typography variant={'body1'}>View your analytics</Typography>
        </Link>
      </Section>

    </Wrapper>
  )
}


const Wrapper = styled.div`
  width: calc(100% - 2 * 24px);
  min-height: calc(100% - 24px * 2);
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: stretch;
  gap: 48px;
`;

const Section = styled.div`
  width: 100%;
`;

const TileWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  overflow-x: scroll;
`;

const TileWrapperInner = styled.div`
  display: flex;
  padding-bottom: 24px;
  flex-direction: row;
  gap: 24px;
`

const LoadingTile = styled.div`
  width: 100%;
  align-self: center;
  justify-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default Home;
