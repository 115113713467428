import asyncPool from 'tiny-async-pool';
import React, { useState, useLayoutEffect, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import FindWebsites from '../screens/FindWebsites';
import ResearchCandidate from '../screens/ResearchCandidate';
import IdentifyRaces from '../screens/IdentifyRaces';
import ElectionResultsTask from '../screens/ElectionResultsTask';
import PublishCandidate from '../screens/PublishCandidate';
import ReportedErrors from '../screens/ReportedErrors';
import FindWebsiteIcon from '../images/icon-find-website.png';
import ResearchCandidateIcon from '../images/icon-research-candidate.png';
import IdentifyRacesIcon from '../images/identify-races-icon.png';
import ElectionResultsIcon from '../images/election-results.png';
import PublishCandidateIcon from '../images/publish-candidate.png';
import ReportedErrorIcon from '../images/reported-errors-icon.png';

const FeathersContext = React.createContext(null);
const FeathersProvider = FeathersContext.Provider;
const adminPerms = ['editor', 'publisher', 'super-admin','admin']


const taskConfigurations = {
  'find-website': {
    title: 'Find a website',
    description: 'Find the website for the candidate',
    color: 'linear-gradient(343deg, rgba(230,232,171,1) 56%, rgba(254,255,215,1) 100%)',
    permissions: ['find-website', 'researcher', 'reviewer', ...adminPerms ],
    icon: FindWebsiteIcon,
    taskPage: () => <FindWebsites />
  },
  'review-candidate': {
    title: 'Research a candidate',
    description: 'Research a candidate running for an upcoming election',
    color: 'linear-gradient(343deg, rgba(180,179,248,1) 56%, rgba(212,211,246,1) 100%)',
    permissions: ['researcher', 'reviewer', ...adminPerms ],
    icon: ResearchCandidateIcon,
    taskPage: () => <ResearchCandidate />
  },
  'publish-candidate': {
    title: 'Publish a candidate',
    description:
      'Validate and publish a candidate running for an upcoming election',
    color:
      'linear-gradient(343deg, rgba(130,119,236,1) 56%, rgba(165, 174, 255,1) 100%)',
    permissions: [ ...adminPerms ],
    icon: PublishCandidateIcon,
    taskPage: () => <PublishCandidate />,
    additionalTaskQuery: (user) => {
      return {
        // 'details.submittedBy': { $ne: user?._id }
      }
    }
  },
  'identify-races': {
    title: 'Scout ballot items',
    description: 'Identify whats on the ballot in counties across the state',
    color: 'linear-gradient(343deg, rgba(246,161,251,1) 56%, rgba(254, 237, 255,1) 100%)',
    permissions: [...adminPerms ],
    icon: IdentifyRacesIcon,
    taskPage: () => <IdentifyRaces />
  },
  'election-results': {
    title: 'Find election results',
    description: 'Review election results',
    color: 'linear-gradient(343deg, rgba(145, 179, 174,1) 56%, rgba(235, 246, 245,1) 100%)',
    permissions: ['researcher', 'reviewer', ...adminPerms ],
    icon: ElectionResultsIcon,
    taskPage: () => <ElectionResultsTask />
  },
  'reported-error': {
    title: 'Resolve an error',
    description: 'Resolve issues reported by users on the site',
    color: 'linear-gradient(343deg, rgba(245, 100, 100,1) 56%, rgba(235, 246, 245, 1) 100%)',
    permissions: ['researcher', 'reviewer', ...adminPerms ],
    icon: ReportedErrorIcon,
    taskPage: () => <ReportedErrors />
  },
}

const colorsForTask = {
  'find-website': 'linear-gradient(343deg, rgba(230,232,171,1) 56%, rgba(254,255,215,1) 100%)',
  'review-candidate': 'linear-gradient(343deg, rgba(180,179,248,1) 56%, rgba(212,211,246,1) 100%)',
  'identify-races': 'linear-gradient(343deg, rgba(246,161,251,1) 56%, rgba(254, 237, 255,1) 100%)',
}

function useFeathers() {
  return React.useContext(FeathersContext);
}

const asyncPoolAll = async (...args) => {
  const results = [];
  for await (const result of asyncPool(...args)) {
    results.push(result);
  }
  return results;
}

function useWindowDimensions() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

function useFormInputs(
  defaultInputs = {},
) {
  const [ formInputs, setFormInputs ] = useState(defaultInputs);

  const reset = () => setFormInputs(defaultInputs)
  const onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const updateOp = {};
    updateOp[name] = value;
    const newInputs = {
      ...formInputs,
      ...updateOp
    }
    setFormInputs(newInputs);
  }

  return [ formInputs, onChange, reset ];
}

function useHasChanged(val, equalFunction) {
  const prevVal = usePrevious(val)
  if(!prevVal && val) return true;
  if(!val && prevVal) return true;
  if(equalFunction) {
    return !equalFunction(prevVal, val)
  } else {
    return prevVal !== val
  }
}

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function isPermitted(user, permissions) {
  if(!permissions) throw new Error('No permissions provided. Provide an empty array.')
  if(permissions.length === 0) return true;
  if (!user.permissions || user.permissions.length === 0) return false;

  return user.permissions.some(p => permissions.includes(p));
}

const researcherLevelPermissions = {
  1: ['low'], 2: ['low', 'medium'], 3: ['low', 'medium', 'high']
}

const onClickOrLink = (onClick) => {
  if(onClick && typeof(onClick === 'object') && onClick.to) {
    return { to: onClick.to, as: Link }
  } else if(onClick && typeof(onClick === 'object') && onClick.href) {
    return { href: onClick.href, as: 'a', ...(onClick.external ? { target: '_blank' } : {})}
  } else {
    return { onClick };
  }
}

const formatMoney = (number) => {
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(number && !isNaN(number) ? number : 0)
}

/**
 * checks if a string is a URL by checking if domain and extension.  (Does not check for http/https/www. etc)
 * @param {*} string
 * @returns {Boolean}
 */
const isUrl = (string) => (/[a-zA-Z0-9]+\.[^\s]{2,}/gi).test(string)

const coveragePlanSpecifications = {
  'issues-coverage': {
    description: 'Our most thorough coverage of a race, generated manually. Each candidate is expected to have bios, summaries of platforms,' +
      ' along with basic information such as photos, name, website, and socials.',
    assignmentsEnforced: true,
    supportsWorkflow: true,
    minPermissionToPublish: 'publisher',
    progressArrayForCandidate: candidate => ([
      {
        field: 'Personal Background',
        complete: candidate.bioPersonal?.length > 0 || candidate.missingData?.bioPersonal,
        requiredFor: ['completion','publish', 'submission']
      },
      {
        field: 'Professional Background',
        complete: candidate.bioProfessional?.length > 0 || candidate.missingData?.bioProfessional,
        requiredFor: ['completion','publish', 'submission']
      },
      {
        field: 'Political Background',
        complete: candidate.bioPolitical?.length > 0 || candidate.missingData?.bioPolitical,
        requiredFor: ['completion','publish', 'submission']
      },
      {
        field: 'Information Sources',
        complete: candidate.references?.checked,
        requiredFor: ['completion','publish', 'submission']
      },
      {
        field: 'Photo',
        complete: candidate.photoPathFace,
        requiredFor: []
      },
      {
        field: 'Name',
        complete: candidate.name,
        requiredFor: ['completion','publish', 'submission']
      },
      ...(candidate.issues || []).map(({ key, complete }) => ({
        field: 'Issue: ' + key,
        complete,
        requiredFor: ['completion','publish', 'submission']
      })),
      {
        field: 'Qualification Status',
        complete: ['yes','no'].includes(candidate?.qualified),
        requiredFor: ['completion','publish']
      }
    ])
  },
  'auto-coverage': {
    description: 'Full coverage of a candidate, with bios and issues, but mainly done through ai generated content + review.',
    assignmentsEnforced: false,
    supportsWorkflow: false,
    minPermissionToPublish: 'publisher',
    progressArrayForCandidate: candidate => {

      return [
        {
          field: 'Personal Background',
          complete: candidate.bioPersonal?.length > 0 || candidate.missingData?.bioPersonal,
          requiredFor: ['completion','publish', 'submission']
        },
        {
          field: 'Professional Background',
          complete: candidate.bioProfessional?.length > 0 || candidate.missingData?.bioProfessional,
          requiredFor: ['completion','publish', 'submission']
        },
        {
          field: 'Political Background',
          complete: candidate.bioPolitical?.length > 0 || candidate.missingData?.bioPolitical,
          requiredFor: ['completion','publish', 'submission']
        },
        {
          field: 'Information Sources',
          complete: candidate.references?.checked,
          requiredFor: ['completion','publish', 'submission']
        },
        {
          field: 'Photo',
          complete: candidate.photoPathFace,
          requiredFor: []
        },
        {
          field: 'Name',
          complete: candidate.name,
          requiredFor: ['completion','publish', 'submission']
        },
        ...(candidate.issues || []).map(({ key, complete }) => ({
          field: 'Issue: ' + key,
          complete,
          requiredFor: ['completion','publish', 'submission']
        })),
        {
          field: 'Qualification Status',
          complete: ['yes','no'].includes(candidate?.qualified),
          requiredFor: ['completion','publish']
        }
      ]
    }
  },
  'basic-coverage': {
    description: 'Basic coverage of a race. Each candidate has simple identifying information that a voter can use to find more info.' +
      ' This includes website, social media, and photos.',
    assignmentsEnforced: false,
    supportsWorkflow: false,
    minPermissionToPublish: 'reviewer',
    progressArrayForCandidate: candidate => {
      const candidateWebsite = (candidate.references?.categories || []).find(rc => rc.type === 'website');
      const candidateSocials = (candidate.references?.categories || []).find(rc => rc.type === 'social');

      return [
        {
          field: 'Name',
          complete: candidate.name,
          requiredFor: ['completion']
        },
        {
          field: 'Qualification Status',
          complete: ['yes','no'].includes(candidate?.qualified),
          requiredFor: ['completion']
        },
        {
          field: 'Website',
          complete: candidateWebsite?.missing || candidateWebsite?.sources?.length > 0,
          requiredFor: ['completion']
        },
        {
          field: 'Social media',
          complete: candidateSocials?.missing || candidateSocials?.sources?.length > 0,
          requiredFor: ['completion']
        },
        {
          field: 'Photo',
          complete: candidate.photoPathFace,
          requiredFor: []
        }
      ]
    }
  },
  'no-coverage': {
    description: 'We will only have names and party for each candidate. Each candidate is "complete" upon creation.',
    assignmentsEnforced: false,
    supportsWorkflow: false,
    minPermissionToPublish: 'reviewer',
    progressArrayForCandidate: candidate => ([
      {
        field: 'Name',
        complete: candidate.name,
        requiredFor: ['completion']
      },
      {
        field: 'Qualification Status',
        complete: ['yes','no'].includes(candidate?.qualified),
        requiredFor: ['completion']
      }
    ])
  }
}

const mergeReferencesWithDraft = (newReferences, stagingDraft) => {
  if(!newReferences) return;
  const referencesInput = stagingDraft?.references;
  // When we change our references after the initial referenceStage
  // we need to make sure that we migrate all existing sources using that reference to the new reference
  const existingSources = (referencesInput.categories || []).map(c => c.sources || []).flat();
  const newSources = (newReferences.categories || []).map(c => c.sources || []).flat();

  if(newSources?.length > existingSources?.length) {
    // we have a new reference, do nothing special
    return {
      ...stagingDraft,
      references: newReferences
    }
  } else if(newSources?.length < existingSources?.length) {
    const newSourcesByUrl = newSources.map(s => s?.url || s);
    const sourcesToKeep = existingSources
      .filter(s => newSourcesByUrl.includes(s?.url || s))
      .map(s => s?.url || s);
    const sourcesToKeepHostnames = sourcesToKeep.map(s => new URL(s).hostname);

    // a removed source
    const {
      bioPersonalSources,
      bioPoliticalSources,
      bioProfessionalSources,
      issues
    } = (stagingDraft || {});

    return {
      ...stagingDraft,
      references: newReferences,
      bioPersonalSources: (bioPersonalSources || []).filter(s => sourcesToKeep.includes(s?.url)),
      bioPoliticalSources: (bioPoliticalSources || []).filter(s => sourcesToKeep.includes(s?.url)),
      bioProfessionalSources: (bioProfessionalSources || []).filter(s => sourcesToKeep.includes(s?.url)),
      issues: (issues || []).map(iss => ({
        ...(iss || {}),
        stances: (iss.stances || []).map(stance => ({
          ...stance,
          sources: (stance?.sources || []).filter(source => sourcesToKeep.includes(source?.url) || (
            source?.sourceType === 'website'
            ? sourcesToKeepHostnames.includes(new URL(source?.url).hostname)
            : false
          ))
        }))
      }))
    }
  } else {
    // we may have a changed url
    const newSourcesByUrl = newSources.map(s => s?.url || s);
    const previousSourcesByUrl = existingSources.map(s => s?.url || s);
    const previousUrlChangedOpt = previousSourcesByUrl.filter(url => !newSourcesByUrl.includes(url));
    const newUrlChangedOpt = newSourcesByUrl.filter(url => !previousSourcesByUrl.includes(url));

    let previousUrl, newSource;
    if(previousUrlChangedOpt?.length === 0 && newUrlChangedOpt?.length === 0) {
      // same list, meaning some may have just changed names
      const options = newSources.map(newSource => {
        const newSourceUrl = newSource?.url || newSource;
        const prevMatchingSource = existingSources.find(existing => (
          (existing?.url || existing) === newSourceUrl &&
          existing?.title !== newSource?.title
        ))
        if(prevMatchingSource) {
          return {
            newSource,
            previousUrl: (prevMatchingSource?.url || prevMatchingSource)
          }
        } else {
          return null;
        }
      }).filter(Boolean);
      if(options?.length === 1) {
        previousUrl = options[0].previousUrl;
        newSource = options[0].newSource;
      }
    } else if(previousUrlChangedOpt?.length === 1 && newUrlChangedOpt?.length === 1) {
      previousUrl = previousUrlChangedOpt[0];
      newSource = newSources[newSourcesByUrl.indexOf(newUrlChangedOpt[0])];
    }

    if(previousUrl && newSource) {
      if(newSource?.mediaType === 'website') newSource.title = 'Candidate website';
      else if(newSource?.mediaType === 'questionnaire') newSource.title = 'Branch questionnaire';

      console.log('Swapping', previousUrl, 'for ' , newSource)

      const {
        bioPersonalSources,
        bioPoliticalSources,
        bioProfessionalSources,
        issues
      } = (stagingDraft || {});
      const swapSources = source => {
        if(source?.url === previousUrl) return newSource;
        if(['website', 'questionnaire'].includes(newSource?.mediaType) && (source?.mediaType || source?.sourceType) === newSource?.mediaType) return newSource
        return source;
      }

      return {
        ...stagingDraft,
        references: newReferences,
        bioPersonalSources: (bioPersonalSources || []).map(swapSources),
        bioPoliticalSources: (bioPoliticalSources || []).map(swapSources),
        bioProfessionalSources: (bioProfessionalSources || []).map(swapSources),
        issues: (issues || []).map(iss => ({
          ...(iss || {}),
          stances: (iss.stances || []).map(stance => ({
            ...stance,
            sources: (stance?.sources || []).map(swapSources)
          }))
        }))
      }
    } else {
      console.error(`Inconclusive URL change in editExistingReferences`)
    }
  }
  return {
    ...stagingDraft,
    references: newReferences
  }
}

const prettyNameForReportedErrorType = (type) => {
  switch(type) {
    case 'technical': return 'Technical Bug';
    case 'data': return 'Incorrect Data';
    case 'content': return 'Content Issue';
    case 'geographical': return 'Address Mismatch';
    default: return 'Unknown Error';
  }
}

const loadFullDisplayDataForTasks = async (taskType, feathers, tasks, electionsAll) => {
  if (taskType === 'reported-error') {
    const errorIds = tasks.map(task => task.details.reportedError).filter(Boolean);
    const { data: reportedErrors} = await feathers.getService('reported-errors').find({
      query: {
        _id: errorIds,
        $limit: errorIds.length,
      }
    });

    return tasks.map(task => {
      const errorId = task.details.reportedError;
      const re = reportedErrors.find(re => re._id === errorId);
      return {
        ...task,
        name: prettyNameForReportedErrorType(re?.type),
        detailName: `Reported ${moment(re?.createdAt).format('LLL')}`
      }
    })
  } else {
    const detailService = taskType === 'identify-races'
      ? 'districts'
      : 'candidates';
    const detailFieldName = taskType === 'identify-races'
      ? 'district'
      : 'candidate';

    const ids = tasks.map(task => task.details[detailFieldName]).filter(Boolean);
    const electionKeys = [...new Set(tasks.map(task => task.details.election).filter(Boolean))];
    const electionKeysMissing = electionKeys.filter(key => !(electionsAll || []).find(e => e.key === key));
    const [ detailObjectsAll, electionsAdditional ] = await Promise.all([
      feathers.getService(detailService).find({
        query: {
          _id: ids,
          $limit: ids.length,
        }
      }),
      electionKeysMissing?.length > 0 ? feathers.getService('elections').find({
        query: {
          key: electionKeysMissing.slice(0,19),
          $limit: electionKeysMissing.length,
        }
      }) : undefined
    ].filter(Boolean))
    const elections = electionsAdditional ? [...(electionsAll || []), ...electionsAdditional.data] : electionsAll;

    const tasksMappedWithFullDetails = (tasks || []).map(task => {
      const detail = detailObjectsAll.data.find(detailObj => detailObj._id === task.details[detailFieldName]);
      const election = elections.find(e => e.key === task.details?.election);
      return {
        ...task,
        name: detail?.longName || detail?.name,
        detailName: [election?.name, detailService === 'candidates' ? detail?.race?.district?.longName : undefined].filter(Boolean).join(' • ')
      }
    })


    return tasksMappedWithFullDetails;
  }
}

const dataEditorUrlFromEntity = (entity, serviceType) => {
  if(serviceType === 'candidates') {
    return `/elections/${entity?.election?.key || entity?.election}/races/${entity?.race?._id || entity?.race}/candidates/${entity?._id}`;
  } else if(serviceType === 'races') {
    return `/elections/${entity?.election?.key || entity?.election}/races/${entity?._id}`;
  } else if(serviceType === 'elections') {
    return `/elections/${entity?.key}`;
  }
}

/**
 *
 * @param {String} service
 * @param {*} query
 * @param {} feathers
 * @param {*} pageLimit
 * @returns
 */
const loadPaginatedFrontend = async (service, query, feathers, pageLimit = 10) => {
  if (!feathers || !query) return;
  try {
    const adjustedQuery = { ...query, $limit: pageLimit }
    const { total }= await feathers.getService(service).find({ query: adjustedQuery, $limit: 0 })

    const pages = Math.ceil(total / pageLimit)
    const array =  [...Array(pages).keys()]
    const results = await asyncPoolAll(10, array, async (i) => {
      const adjustedQuery = {
        ...query,
        $skip: (i) * pageLimit,
        $limit: pageLimit
      }
      const { data } =  await feathers.getService(service).find({ query: adjustedQuery })
        return data
      })
      return results.flat()
  } catch (err) {
    console.log('loadPaginatedFrontend error: ', err)
  }
};

export {
  useWindowDimensions,
  useFormInputs,
  mergeReferencesWithDraft,
  useHasChanged,
  useFeathers,
  isPermitted,
  loadFullDisplayDataForTasks,
  coveragePlanSpecifications,
  FeathersProvider,
  onClickOrLink,
  formatMoney,
  usePrevious,
  isUrl,
  dataEditorUrlFromEntity,
  colorsForTask,
  taskConfigurations,
  asyncPoolAll,
  loadPaginatedFrontend,
  researcherLevelPermissions
};
