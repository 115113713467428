import React, { useState, useEffect } from 'react';
import {
  useFeathers,
  mergeReferencesWithDraft,
} from '../../app/util';
import Button from '@material-ui/core/Button';

import {
  ReferenceEditor,
  ReviewProgress,
  TaskPage,
  EditCandidateProfile,
  mapDraftToServerInputs,
  newDraftAfterError,
  TimeTracker,
} from '../../components';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSelector } from 'react-redux';

const ResearchCandidate = () => {
  const feathers = useFeathers();
  const user = useSelector(state => state.user);
  const [task, setTask] = useState(null);
  const [candidate, setCandidate] = useState(null);
  const [race, setRace] = useState(null);
  const [saving, setSaving] = useState(false); 
  const [taskPageSaving, setTaskPageSaving] = useState(false);
  const [completionData, setCompletionData] = useState(null)
  const [publishCandidate, setPublishCandidate] = useState(null)
  const [stagedUpdatesToReferences, setStagedUpdatesToReferences] = useState(null);
  const [showingPublishDialog, setShowingPublishDialog] = useState(false);
  const publishPerms = (user?.permissions || []).some((p) =>
    ['publisher', 'editor', 'super-admin'].includes(p)
  );

  /* Candidate being actively edited */
  const [candidateEdit, setCandidateEdit] = useState({});
  
  useEffect(() => {
    if (stagedUpdatesToReferences && candidateEdit) {
      setStagedUpdatesToReferences(null);
      setCandidateEdit(
        mergeReferencesWithDraft(
          stagedUpdatesToReferences,
          candidateEdit
        )
      );
    }
  }, [candidateEdit, stagedUpdatesToReferences, task]);

  
  const onCheckoutTask = async (task) => {
    setCandidate(null);
    setCandidateEdit(null);
    setRace(null);
    setTask(task);
    const candidate = await feathers.getService('candidates').get(task.details.candidate);
    setCandidate(candidate);
    const { updatedAt: holdingDataUpdatedAt, ...holdingData } = task?.holdingData || {};
    // seed inputs with most recently updated data from holdingData or candidate record
    const { bioPersonal, bioProfessional, bioPolitical, missingData, ...rest } = (
      ((holdingData && holdingDataUpdatedAt > candidate?.updatedAt)
        ? holdingData
        : candidate?.stagingDraft) 
      || {}
    )

    const mappedCandidate = {
      ...rest,
      missingData,
      ...(missingData?.bioPersonal
        ? { bioPersonal: '' }
        : { bioPersonal }),
      ...(missingData?.bioProfessional
        ? { bioProfessional: '' }
        : { bioProfessional }),
      ...(missingData?.bioPolitical
        ? { bioPolitical: '' }
        : { bioPolitical }),
    };

    setCandidateEdit(mappedCandidate);

    // get race separately since the aggregated race object attached to candidate is missing some fields
    const race = await feathers.getService('races').get(candidate.race?._id);
    setRace(race);
  }

  useEffect(() => {
    if (candidate && candidateEdit) setCompletionData(mapDraftToServerInputs(candidateEdit))
  }, [candidate, candidateEdit, task])
  
  const onCompleteError = (e) => { setCandidate((c) => newDraftAfterError(c, e)) }; 

  useEffect(() => {
    if(candidate) setPublishCandidate(candidate._id)
  }, [candidate, task])

  const handlePublish = async (loadNextTask) => {
    if(saving) return;

    setSaving(true)
    try {
      await feathers.getService('candidates').patch(publishCandidate, {
        $publish: true,
      });
      setShowingPublishDialog(false);
      loadNextTask();
    } catch (e) {
      console.log('Error', e)
    } finally {
      setSaving(false)
      setShowingPublishDialog(false);
      loadNextTask()
    }
  };

  const dialogText = {
    title: 'Publish candidate?',
    body: `Do you want to publish this candidate's profile? If you
    click "Publish", it will be immediately available to the
    public. If you click "Not yet", it will be sent to the
    queue for review by another reviewer.`,
    cancel: 'Not yet',
    confirm: 'Publish'
  }

  return (
    <TaskPage
      task={task}
      taskType='review-candidate'
      onCheckoutTask={onCheckoutTask}
      completionData={completionData}
      holdingData={candidateEdit}
      onChangeSaving={(saving) => setTaskPageSaving(saving)}
      onCompleteError={onCompleteError}
      disableTimeTracker
      onCompleteTaskBeforeLoadNext={publishPerms ? () => setShowingPublishDialog(true) : null}
      onCompleteTaskDialog={
        (loadNextTask) => (
          <Dialog
            open={showingPublishDialog}
            onClose={() => {
              loadNextTask()
              setShowingPublishDialog(false)
            }}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-body'
          >
            <DialogTitle id='alert-dialog-title'>
              {dialogText?.title}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-body'>
                {dialogText?.body}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {
                loadNextTask()
                setShowingPublishDialog(false)
              }}
                color='primary'>
                {dialogText?.cancel}
              </Button>
              <Button onClick={() => handlePublish(loadNextTask)} color='primary' autoFocus>
                {dialogText?.confirm}
              </Button>
            </DialogActions>
          </Dialog>
        )
      }
      sidePaneConfig={([
        {
          title: 'Priority',
          content: (
            <TimeTracker
              seedTask={task}
              priorityLevel={candidate?.priorityLevel}
            />
          ),
          initiallyExpanded: true,
        },
        (!!candidate?.coveragePlan &&
        {
          title: 'Progress',
          content: (
            <ReviewProgress
              coveragePlan={candidate?.coveragePlan}
              candidate={candidateEdit}
              key={`Progress`}
            />
          ),
          initiallyExpanded: true,
        }),
        (!!candidateEdit?.references &&
        {
          title: 'Information sources',
          content: (
            <ReferenceEditor
              references={candidateEdit?.references}
              onChangeReferences={setStagedUpdatesToReferences}
              key="Information sources"
              disabled={saving} // being set in child via handleParentSaving
              activeDraft={candidateEdit}
              newsEnabled
            />
          ),
          initiallyExpanded: true,
        }),
      ])}
    >
      <WrapperInner>
        <EditCandidateProfile
          value={candidateEdit}
          onChange={setCandidateEdit}
          onChangeIssue={(newIssue, issueKey) => {
            setCandidateEdit(existingDraft => {
              const existingDraftIssues = existingDraft.issues || [];
              const newIssues = existingDraftIssues.map((issue, i) => {
                if(issue.key === issueKey) {
                  return newIssue
                }
                return issue
              })

              return {
                ...existingDraft,
                issues: newIssues
              }
            })
          }}
          candidateId={candidate?._id}
          photoOptions={candidate?.photoOptions}
          race={race}
          disabled={saving || taskPageSaving} // being set in child
          electionKey={
            candidate?.election?.key || candidate?.election
          }
        />
      </WrapperInner>
    </TaskPage>
  );
};

const WrapperInner = styled.div`
  padding: 0 24px;
`
export default ResearchCandidate;
