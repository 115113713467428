import React, { useEffect } from 'react';
import styled from 'styled-components';
import * as d3 from 'd3';
import moment from 'moment';

const LineGraph = ({
  data: dataGiven,
  startDate,
  endDate,
  className,
  style
}) => {

  const createGraph = async () => {
      // read data from csv and format variables
      let data = dataGiven.slice();
      data = datesPadded(startDate, endDate, data);


      var parseTime = d3.timeParse("%Y-%m-%d");
    
      data.forEach((d) => {
        d.date = parseTime(d.date);
        d.value = +d.value;
      });
      console.log(data)
  
      // set the dimensions and margins of the graph
      var margin = { top: 20, right: 20, bottom: 50, left: 70 },
      width = 960 - margin.left - margin.right,
      height = 500 - margin.top - margin.bottom;
  
      // append the svg object to the body of the page
      var svg = d3.select(".viz").append("svg")
          .attr("width", width + margin.left + margin.right)
          .attr("height", height + margin.top + margin.bottom)
          .append("g")
          .attr("transform", `translate(${margin.left}, ${margin.top})`);
  
      // add X axis and Y axis
      var x = d3.scaleTime().range([0, width]);
      var y = d3.scaleLinear().range([height, 0]);
  
      if(startDate && endDate) {
        x.domain([parseTime(startDate), parseTime(endDate)]);
      } else {
        x.domain(d3.extent(data, (d) => { return d.date; }));
      }
      y.domain([0, d3.max(data, (d) => { return d.value; })]);
    
      svg.append("g")
        .attr("transform", `translate(0, ${height})`)
        .call(d3.axisBottom(x));
  
      svg.append("g")
        .call(d3.axisLeft(y));
        
      // add the Line
      var valueLine = d3.line()
      .x((d) => { return x(d.date); })
      .y((d) => { return y(d.value); })
      .curve(d3.curveLinear);
    
      svg.append("path")
        .data([data])
        .attr("class", "line")
        .attr("fill", "none")
        .attr("stroke", "steelblue")
        .attr("stroke-width", 1.5)
        .attr("d", valueLine);
  
    }
  
    useEffect(() => {
      if(dataGiven.length > 0 ) createGraph();
    }, [ dataGiven ]);

  return (
    <Wrapper className={className} style={style}>
      <div class="viz" style={style}>
      </div>
    </Wrapper>
  );
}

const datesPadded = (startDate, endDate, results) => {
  const numDays = moment.utc(endDate).diff(moment.utc(startDate), 'days') + 1;
  const dateRangeEmpty = [...Array(numDays)].map((bl, i) => moment.utc(startDate).add(i, 'days').locale('en').format('YYYY-MM-DD'));
  return dateRangeEmpty.map(dateX => {
    const { date, ...result } = (results || []).find(res => res?.date === dateX) || {};
    return {
      date: dateX,
      value: result?.value ?? 0
    }
  })
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export { LineGraph };