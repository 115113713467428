import React, { useState, useEffect, useMemo } from "react";
import Typography from "@material-ui/core/Typography";
import { useFeathers, loadPaginatedFrontend } from "../../app/util";
import TextField from '@material-ui/core/TextField';
import {
  Modal,
  RaceCandidatePreviewTile,
  CreateRaceModal,
  ChipMultiSelectMenu,
  HintTile
} from "../../components";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { useFindIntersectingDistrictsFull  } from "../../app/hooks/useFindIntersectingDistrictsFull";
import { OpenInNew as OpenInNewIcon } from "@material-ui/icons";
import { Link } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const ScoutRacesForArea = ({
	primaryDistrictId,
	election,
	onChangeCompletionData,
	disabled,
  allowDirectEdits = false
}) => {
	const feathers = useFeathers();
  const [error, setError] = useState(false);

	/* our backbone data */
  const [ electionFull, setElectionFull ] = useState(null);
  const [ primaryScoutingDistrict, setPrimaryScoutingDistrict ] = useState(null);
  const [ district, setDistrict ] = useState(null);
	const [ districtDescendants, setDistrictDescendants ] = useState([])
  const [ additionalDistricts, setAdditionalDistricts ] = useState([])
  const [ additionalDistrictsDescendants, setAdditionalDistrictsDescendants ] = useState({}) // format is { parentId: [districtDescendants] }

	const { districts: intersectingDistrictsOptions } = useFindIntersectingDistrictsFull(district, { type: ['city', 'county']  });
	
	/* Our input data, updated each time we edit the page */
  const [ possibleDistrictTypes, setPossibleDistrictTypes ] = useState({}) // form { dId: [ districtTypes ]}
  const [ source, setSource ] = useState('');
  const [ races, setRaces ] = useState([]);
  const [ additionalRaces, setAdditionalRaces ] = useState({}) // format is { tier2Id: [races]}

  const [ queueLoadRaces, setQueueLoadRaces ] = useState(false);
  const [ addRaceModalOpen, setAddRaceModalOpen ] = useState(null); // null or district (full)
  const [ sourceWasEdited, setSourceWasEdited ] = useState(false);
  const [ additionalDistrictsEdited, setAdditionalDistrictsEdited ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ savingUpdates, setSavingUpdates ] = useState(false);

  const isPrimaryDistrict = primaryScoutingDistrict === district?._id;

	/* Upon initial loading, load all of our backbone data */
  const loadData = async () => {
    setLoading(true)
    setAdditionalDistricts([])
    setSource('')
    setSourceWasEdited(false)
    setPrimaryScoutingDistrict(null)
    setAdditionalDistrictsEdited(false)
    await loadElection(election);
    await loadStartingData(election, primaryDistrictId);
    setQueueLoadRaces(true)
  }

	const loadElection = async (election) => {
    const electionFull = await feathers
      .getService("elections")
      .get(election);
    setElectionFull(electionFull);
  };

  const loadStartingData = async (electionKey, district) => {
    const primaryDistrictData = await loadDistricts([district])

    setDistrict(primaryDistrictData.districts[0])
    setDistrictDescendants(Object.values(primaryDistrictData.districtDescendants)[0])
    setPossibleDistrictTypes(pdt => ({ ...pdt, ...primaryDistrictData.possibleDistrictTypes }))

    const primaryDistrictStatusOptions = await feathers.getService('implementation-districts').find({
      query: {
        election: electionKey,
        active: true,
        district: district,
        contentType: 'races',
        $limit: 200,
        $includeInternalFields: true
      },
    });

    const primaryDistrictStatus = primaryDistrictStatusOptions.data.find(ds => ds.district === district);

    if (!primaryDistrictStatus) {
      throw new Error('No district status found for primary district')
    }

    if (primaryDistrictStatus.source) {
      setSource(primaryDistrictStatus.source)
    }

    if(primaryDistrictStatus.scoutedWithDistricts?.length > 0) {
      const additionalIds = primaryDistrictStatus.scoutedWithDistricts.filter(d => d !== district)
      console.log('loading additional districts', additionalIds)
      const additionalDistricts = await loadDistricts(additionalIds)

      console.log('additionalDistrictsretruend', additionalDistricts)
      setAdditionalDistricts(additionalDistricts.districts)
      setAdditionalDistrictsDescendants(additionalDistricts.districtDescendants)
      setPossibleDistrictTypes(pdt => ({ ...pdt, ...additionalDistricts.possibleDistrictTypes }))

      if(primaryDistrictStatus.isPrimaryDistrict) {
        setPrimaryScoutingDistrict(district)
      } else {
        const otherImplementationDistricts = await feathers.getService('implementation-districts').find({
          query: {
            election: electionKey,
            active: true,
            district: additionalIds,
            contentType: 'races',
            $limit: additionalDistricts.length,
            $includeInternalFields: true
          },
        });

        const primaryDistrict = otherImplementationDistricts.data.find(d => d.isPrimaryDistrict)
        if (primaryDistrict) {
          setPrimaryScoutingDistrict(primaryDistrict.district)
        } else {
          throw new Error('No primary district found in additional districts')
        }
      }
    } else {
      setPrimaryScoutingDistrict(district)
    }
  }

  const loadDistricts = async (districtIds) => {
    // this function takes a district id and loads it and it's descendants and possible district types
    if (!feathers) return

    const districtsFull = (await feathers
      .getService("districts")
      .find({
        query: {
          _id: districtIds,
          parentDepth: 5,                 // figure out how to bring back district names as well
          $includeIntersectsWith: true,
          $limit: districtIds.length
        },
      })).data;

    // for the edge case of a state -- only allow research done at the state level , because there are too many descendants and the research for the counties should be done by county , not at the state level
    const stateDistrict = districtsFull.find(d => d.type === 'state')
    if (stateDistrict) {
      return {
        districts: districtsFull,
        districtDescendants: [],
        possibleDistrictTypes: { [stateDistrict._id]: 'state' }
      }
    }

    const descendantOfQuery =  {
      parentId: districtsFull.map(d => d._id)
    }

    const districtDescendants = await loadPaginatedFrontend('districts', descendantOfQuery, feathers, 50);

    const aggregatedUpdates = districtsFull.reduce((acc, d) => {
      const descendantsForDistrict = districtDescendants.filter(dd => dd.parentId === d._id)
      acc.districtDescendants[d._id] = descendantsForDistrict;
      const possibleTypes = [
        ...new Set([
          ...descendantsForDistrict.map(dd => dd.type),
          d.type
        ])
      ]
      acc.possibleDistrictTypes[d._id] = possibleTypes;
      return acc;
    }, { districtDescendants: {}, possibleDistrictTypes: {} })

    return {
      districts: districtsFull,
      districtDescendants: aggregatedUpdates.districtDescendants,
      possibleDistrictTypes:  aggregatedUpdates.possibleDistrictTypes
    }
  };

  const loadRaces = async () => {
    await loadExistingRaces()
    await loadAdditionalRaces()
    setLoading(false)
  }

  const loadExistingRaces = async () => {
    if (!primaryDistrictId || !election) return;
    const racesQuery = {
      election: election,
      district: [
        ...((districtDescendants || [])?.map((d) => d._id)),
        primaryDistrictId,
      ],
      $includeParentDistrict: true,
      $sort: { createdAt: 1 }
    }
    const numRacesInDb = await feathers.getService("races").find({
      query: {
        ...racesQuery,
        $limit: 0
      },
    });

    const chunkSize = 20;
    const numChunks = Math.ceil(numRacesInDb.total / chunkSize);

    const racesInDb = await Promise.all(
      Array.from({ length: numChunks }).map((_, i) => {
        return feathers.getService("races").find({
          query: {
            ...racesQuery,
            $limit: chunkSize,
            $skip: i * chunkSize
          },
        });
      })
    )

    setRaces(racesInDb.map(g => g.data).flat());
  }

  const loadAdditionalRaces = async () => {
    if (!additionalDistricts.length) {
      setAdditionalRaces({})
      return
    }
    const additionalDistrictsIds = additionalDistricts.map(d => d._id)
    const additionalDistrictDescendantsIds = Object.values(additionalDistrictsDescendants).flat().map(d => d._id)

    const additionalRacesQuery ={
        district: [
          ...additionalDistrictsIds,
        ],
        election,
        $includeParentDistrict: true,
    }

    const additionalRaces = await loadPaginatedFrontend('races', additionalRacesQuery, feathers)
    const newAdditionalRaces = additionalRaces.reduce((acc, r) => {
      if (!acc[r.district._id]) {
        acc[r.district._id] = []
      }
      acc[r.district._id].push(r)
      return acc
    }, {})

    const additionalDescendantRacesQuery =  {
      district: [
        ...additionalDistrictDescendantsIds,
      ],
      election,
      $includeParentDistrict: true
    }

    const additionalDescendantRaces = await loadPaginatedFrontend('races', additionalDescendantRacesQuery, feathers)

    const newAdditionalDescendantRaces = additionalDescendantRaces.reduce((acc, r) => {
      if (!acc[r.district.parentId]) {
        acc[r.district.parentId] = []
      }
      acc[r.district.parentId].push(r)
      return acc
    }, newAdditionalRaces)

    setAdditionalRaces(newAdditionalDescendantRaces)
  }

	useEffect(() => {
		if(feathers && primaryDistrictId && election) loadData()
	}, [feathers, primaryDistrictId, election ])


  useEffect(() => {
    if (!feathers) return
    if(queueLoadRaces) {
      setQueueLoadRaces(false)
      loadRaces()
    }
  }, [queueLoadRaces])
	/* End backbone data */
	
	/* managing all of our input data */
	const allDistricts = useMemo(() => {
    return [district, ...additionalDistricts].filter(d => d)
  }, [district, additionalDistricts])

  const allRaces = useMemo(() => {
    if (!district) return {}
    return { ...additionalRaces, [district._id]: races }
  }, [races, additionalRaces])

  const sourceIdentified = useMemo(() => source?.length > 7, [source]);

  const removeCandidateFromEditRaces = async (candidateId, d) => {
    try {
      await feathers.getService('candidates').remove(candidateId)
      if (d._id === district._id) {
      await loadExistingRaces()
      } else {
        await loadAdditionalRaces()
      }
    } catch (err) {
      setError(err)
    }
  };

  useEffect(() => {
    if(loading) return;
    if(onChangeCompletionData) onChangeCompletionData({ source, additionalDistricts: additionalDistricts.map(d => d._id ?? d) })
  }, [source, additionalDistricts, loading ])

  const onRaceCreated = (race) => {
    if (addRaceModalOpen._id === district._id) {
      setRaces(er => [...er, race])
    } else {
      const raceGroup = additionalRaces[addRaceModalOpen._id] || []
      const updatedRaces = [ ...raceGroup, race]
      setAdditionalRaces(er => ({ ...er, [addRaceModalOpen._id]: updatedRaces }))
    }
    setAddRaceModalOpen(null);
  }

  const onAddCandidate = async (candidate, currentDistrict) => {
    const raceIndex = allRaces[currentDistrict._id].findIndex(r => r._id === (candidate?.race?._id || candidate?.race));

    const currentRace = allRaces[currentDistrict._id][raceIndex]
    const raceNew = {
      ...currentRace,
      candidates: [
        ...(currentRace.candidates || []),
        candidate
      ]
    };

    const mainDistrict = currentDistrict._id === district._id
    if (mainDistrict) {
      setRaces(existingRaces => {
        const newRaces = [...existingRaces];
        newRaces[raceIndex] = raceNew;
        return newRaces;
      })
    } else {
      const raceGroup = [...additionalRaces[currentDistrict._id] || []]
      raceGroup[raceIndex] = raceNew
      setAdditionalRaces(er => ({ ...er, [currentDistrict._id]: raceGroup }))
    }

    loadExistingRaces()
  }
	/* End manage all of our input data */
  const saveUpdates = async (saveSource, saveScoutedWith) => {
    const additionalDistrictIds = additionalDistricts.map(d => d._id || d)
    const districtIdsToUpdate = [primaryDistrictId, ...(additionalDistrictIds || [])];
    setSavingUpdates(true)

    const allUpdates = await Promise.all(districtIdsToUpdate.map(async (districtId) => {
      const implementationDistrictOptions = await feathers.getService('implementation-districts').find({
        query: {
          district: districtId,
          election,
          active: true,
          contentType: 'races',
          $includeInternalFields: true
        }
      });
  
      if(implementationDistrictOptions.total !== 1) {
        throw new Error(`No (or multiple) implementation district found for district: ${districtId} and election: ${election}`, { implementationDistrictOptions })
      } else {
        const implementationDistrictBeforeUpdate = implementationDistrictOptions.data[0];
        const otherDistricts = districtIdsToUpdate.filter(id => `${id}` !== `${districtId}`);
  
        const dataToPatch = {
          ...(saveSource ? { source } : {}),
          ...(
            saveScoutedWith ? {
              scoutedWithDistricts: otherDistricts,
              isPrimaryDistrict: `${districtId}` === `${primaryDistrictId}`,
            } : {}
          )
        };
  
        const updated = await feathers
          .getService('implementation-districts')
          .patch(implementationDistrictBeforeUpdate._id,
            dataToPatch,
            {
              query: { $includeInternalFields: true }
            }
          );
        return { beforeUpdate: implementationDistrictBeforeUpdate, afterUpdate: updated };
      }
    }))

    if(saveSource) {
      setSourceWasEdited(false)
    }
    
    console.log(`Updated ${allUpdates.length} districts`);
    if(!saveScoutedWith) {
      setSavingUpdates(false)
      return;
    }
    
    // we need to also onsider the case of removing a district. 
    // if a district was previously a part of this scoutedWithDistricts array, but is no longer,
    // it won't show up in the list.
    // we look at each implementationDistrictBeforeUpdate and use that to make the determination of 
    // what needs to be updated
    const allScoutedWithBeforeUpdate = [...new Set(allUpdates.map(d => d.beforeUpdate.scoutedWithDistricts).flat().map(d => `${d}`))];
    const allScoutedWithRemoved = allScoutedWithBeforeUpdate.filter(d => !districtIdsToUpdate.includes(`${d}`));
  
    const updatesSecondary = await Promise.all(allScoutedWithRemoved.map(async (districtId) => {
      const implementationDistrictOptions = await feathers.getService('implementation-districts').find({
        query: {
          district: districtId,
          election,
          active: true,
          contentType: 'races',
          $includeInternalFields: true
        }
      });
  
      if(implementationDistrictOptions.total !== 1) {
        throw new Error(`No (or multiple) implementation district found for district: ${districtId} and election: ${election}`, { implementationDistrictOptions })
      }
  
      const implementationDistrictBeforeUpdate = implementationDistrictOptions.data[0];
      const scoutedWithNew = implementationDistrictBeforeUpdate.scoutedWithDistricts.filter(d => !districtIdsToUpdate.includes(`${d}`) && `${d}` !== `${districtId}`);
  
      const updated = await feathers.getService('implementation-districts').patch(
        implementationDistrictBeforeUpdate._id,
        {
          scoutedWithDistricts: scoutedWithNew,
          status: 'incomplete'
        },
        {
          query: { $includeInternalFields: true }
        }
      );
    }));
    
    console.log(`Disassociated ${updatesSecondary.length} districts`);
    setAdditionalDistrictsEdited(false)
    setSavingUpdates(false)
  }


	return (
		<div>
			<div style={{ display: 'grid', gridTemplateColumns: 'auto auto', justifyContent: 'start', alignItems: 'center', gridGap: '16px 12px',  marginBottom: '36px' }}>
				<Typography variant="body2">Area</Typography>
				<Typography variant="body1" style={{ fontSize: '18px'}}><b>{district?.originalDistrict?.longName || district?.longName}</b> in {district?.parentDistrict?.originalDistrict?.shortName || district?.parentDistrict?.shortName}</Typography>

				<Typography variant="body2">Election</Typography>
				<Typography variant="body1" style={{ fontSize: '18px'}}><b>{electionFull?.name}</b></Typography>
			</div>
      {
        !isPrimaryDistrict &&
        <HintTile style={{ marginBottom: '20px'}}>
          <Typography variant='body1' style={{ fontWeight: 'bold', marginBottom: '12px'}}>You cannot edit this district directly.</Typography>
          <Typography variant='body1'>This district is being scouted with another district. Please make changes to {additionalDistricts.find(ad => ad._id === primaryScoutingDistrict)?.longName}. If this is a mistake, you can dissociate these two districts by removing this district from the primary district.</Typography>
        </HintTile>
      }
			<div style={{ marginBottom: '20px', display: 'flex', flexDirection: 'column', gap: '0px' }}>
				<Typography variant="h4" style={{ marginBottom: '12px' }}>Where are you getting your information from?</Typography>
        <Typography variant='body1'>Make sure this is a URL to the page. If this is a document from a government website, link to the parent page where the document is listed, not the document directly.</Typography>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '12px', marginTop: '12px' }}>
          <TextField
            label="Source"
            size='small'
            variant="outlined"
            onChange={(e) => {
              setSource(e.target.value)
              setSourceWasEdited(true)
            }}
            style={{ minWidth: '400px' }}
            value={source}
          />
          {
            sourceWasEdited && allowDirectEdits && isPrimaryDistrict && (
              <Button
                variant="contained"
                color="primary"
                size='small'
                disabled={savingUpdates}
                onClick={() => saveUpdates(true, false)}
              >
                {savingUpdates ? 'Saving...' : 'Save'}
              </Button>
            )
          }
          {
            !sourceWasEdited && source && (
              <Link href={source} target="_blank">
                <OpenInNewIcon />
              </Link>
            )
          }
        </div>
        {
          source && !source.startsWith('http') && (
            <Typography variant='body2' style={{ color: 'red' }}>Please make sure the source starts with http or https</Typography>
          )
        }
			</div>
			<Divider />
			{
				['city', 'county'].includes(district?.type) &&
				<>
					<AdditionalDistrictsWrapper>
            <div style={{ display: 'flex', gap: '30px', alignItems: 'center', marginBottom: '12px' }}>
              <Typography variant="h4" style={{  }}>Are there other districts in this source?</Typography>
              {
                additionalDistrictsEdited && allowDirectEdits && isPrimaryDistrict && (
                  <Button
                    variant="contained"
                    color="primary"
                    size='small'
                    disabled={savingUpdates}
                    onClick={() => saveUpdates(false, true)}
                  >
                    {savingUpdates ? 'Saving...' : 'Save'}
                  </Button>
                )
              }
            </div>
            <ChipMultiSelectMenu
              selectedValues={additionalDistricts}
              options={intersectingDistrictsOptions}
              selectOptionFunction={e => {
                setAdditionalDistricts([...additionalDistricts, e])
                setAdditionalDistrictsEdited(true)
              }}
              deselectOptionFunction={e => {
                setAdditionalDistricts(additionalDistricts.filter(d => d._id !== e._id))
                setAdditionalDistrictsEdited(true)
              }}
              displayProp='longName'
              labelText='Additional Districts'
              verticalChips={true}
            />
					</AdditionalDistrictsWrapper>
					<Divider style={{ marginTop: '24px' }}/>
				</>
			}
			<div style={{ ...((sourceIdentified && !disabled) ? {} : { opacity: 0.4, pointerEvents: 'none' }), marginTop: '32px', maxWidth: '800px'}}>
        {
          loading && (
            <div style={{ }}>
							<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: "8px", marginBottom: '2px', gap: '12px' }}>
                <Skeleton variant="text" width={200} height={30} />
              </div>
              <Skeleton variant="rect" width={800} height={200} />
            </div>
          )
        }
				{
					!loading && allDistricts.map((d, i) => {
						return (
							<div style={{ marginBottom: "64px", }} key={d?._id}
							>
								<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: "8px", marginBottom: '2px', gap: '12px' }}>
									<Typography variant="h4" style={{}}>{ d.longName }</Typography>
									<Typography variant='body1' style={{ opacity: 0.8 }}>{(allRaces[d._id] || []).length} races</Typography>
								</div>
								{
									allRaces[d._id]?.map((r) => (
										<RaceCandidatePreviewTile
											onAddCandidate={(cdNew) => onAddCandidate(cdNew, d)}
											key={r._id}
											race={r}
											onError={setError} // send this error down create handle error function
											removeCandidate={(cdId) => removeCandidateFromEditRaces(cdId, d)}
										/>
									))
								}
								<Button
									variant="contained"
									style={{ width: "150px", alignSelf: "flex-end", margin: "20px" }}
									color="primary"
                  disabled={!isPrimaryDistrict}
									onClick={() => setAddRaceModalOpen(d)}
								>
									Add race
								</Button>
							</div>
						)
					})
				}
			</div>
			{
        addRaceModalOpen && (
        <Modal onClose={() => {
          setAddRaceModalOpen(null);
        }}>
          <CreateRaceModal
            onClose={() => {
              setAddRaceModalOpen(null);
            }}
            restrictToDistrict={addRaceModalOpen}
            restrictToOfficesWithDistrictTypes={possibleDistrictTypes[addRaceModalOpen._id]}
            election={electionFull}
            onSaveCreateRace={false}
            coveragePlan={'auto-coverage'}
            onFinish={(race) => onRaceCreated(race)}
          />
        </Modal>
      )}
		</div>
	)
}

const AdditionalDistrictsWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`;

const Divider = styled.hr`
  width: 400px;
  background-color: #e3e3e3;
  margin: 0;
  border: none;
  height: 1px;
`


// const loadAdditionalDistrictDescendants = async () => {
//   if (!additionalDistricts.length) {
//     setAdditionalDistrictsDescendants({})
//     return
//   }

//   const descendantQuery ={
//     $descendantOf: additionalDistricts.map(d => d._id)
//   }

//   const additionalDistrictDescendants = await loadPaginatedFrontend('districts', descendantQuery, feathers, 10)

//   // hash map by parentId
//   const newAdditionalDistrictsDescendants = additionalDistrictDescendants.reduce((acc, d) => {
//     if (!acc[d.parent]) {
//       acc[d.parent] = []
//     }
//     acc[d.parent].push(d)
//     return acc
//   }, {})

//   const possibleTypesParents = additionalDistricts.reduce((acc, d) => {
//       acc[d._id] = [ d.type ]
//       return acc
//   }, {})

//   const allPossibleTypes = additionalDistrictDescendants.reduce((acc, d) => {
//     if (!acc[d.parentId]) {
//       acc[d.parentId] = []
//     }
//     acc[d.parentId] = [...new Set([
//       ...acc[d.parentId],
//       d.type
//     ])]
//     return acc
//   }, possibleTypesParents)

//   setAdditionalDistrictsDescendants(newAdditionalDistrictsDescendants)
//   setPossibleDistrictTypes({...possibleDistrictTypes, ...allPossibleTypes})
// }

export { ScoutRacesForArea }