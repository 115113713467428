import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { useState, useEffect } from 'react';
import { useFeathers } from '../../app/util';
import styled from 'styled-components';
import { CheckCircle, Error } from '@material-ui/icons';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import CodeIcon from '@material-ui/icons/Code';
import NoteIcon from '@material-ui/icons/Note';
import InProgressIcon from '@material-ui/icons/PlayCircleFilled';
import { Done, DoneAll } from '@material-ui/icons';
import UsersIcon from '@material-ui/icons/PeopleAlt';
import ViewsIcon from '@material-ui/icons/Visibility';
import Pagination from '@material-ui/lab/Pagination';

const SupportedDistrictList = ({
  statusFilter,
  typeFilter,
  supportedDistricts,
  pageNumber,
  onChangePageNumber,
  electionKey,
  onChangeCount,
  style,
  className,
}) => {
  const feathers = useFeathers();
  const [ implementationDistricts, setImplementationDistricts ] = useState(null);
  const [supportedDistrictsFull, setSupportedDistrictsFull] = useState(null);
  const [tasksForDistricts, setTasksForDistricts] = useState(false);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);
  const numPerPage = 50;  // the number of districts (supportedDistricts) to show per page

  const districtStatusesById = React.useMemo(() => {
    return (implementationDistricts || []).reduce((acc, v) => {
      if(acc[v.district]) {
        acc[v.district].push(v);
      } else {
        acc[v.district] = [v];
      }
      return acc;
    }, {});
  }, [implementationDistricts]);


  const loadDistricts = async () => {
    setLoading(true)
  

    /* our first query comes from the implementation-districts service */
    let allowedFilters = [];
    switch(statusFilter) {
      case 'complete':
        allowedFilters = ['complete'];
        break;
      case 'incomplete':
        allowedFilters = ['incomplete'];
        break;
      default:
        allowedFilters = ['complete', 'incomplete'];
        break;
    }
    const districtType = typeFilter === 'all' ? null : typeFilter;

    const queryCommon = {
      election: electionKey,
      active: true,
      contentType: 'races',
      status: allowedFilters,
      ...(districtType ? { districtType } : {})
    }

    const implementationDistrictsCount = (await feathers.getService('implementation-districts').find({
      query: {
        ...queryCommon,
        $limit: 0
      }
    })).total;

    if(onChangeCount) onChangeCount(implementationDistrictsCount);
    setCount(implementationDistrictsCount);

    const implementationDistrictsRaces = await feathers.getService('implementation-districts').find({
      query: {
        ...queryCommon,
        $limit: numPerPage,
        $skip: numPerPage * (pageNumber - 1),
        $includeInternalFields: true,
        $includeAnalytics: true,
        $sort: { expectedReadership: -1, districtLongName: 1 }
      },
    });

    const implementationDistrictsMeasures = await feathers.getService('implementation-districts').find({
      query: {
        ...queryCommon,
        contentType: 'measures',
        district: implementationDistrictsRaces.data.map(d => d.district),
        $limit: numPerPage,
        $includeInternalFields: true,
        $includeAnalytics: true,
        $sort: { expectedReadership: -1, districtLongName: 1 }
      },
    });

    const implementationDistrictsAll = [].concat(implementationDistrictsRaces.data, implementationDistrictsMeasures.data);

    setImplementationDistricts(implementationDistrictsAll);
    const orderOfID = implementationDistrictsAll.map(d => d.district);

    const filteredSupportedDistricts = [...new Set(implementationDistrictsAll.map(d => d.district))]
    const numDistricts = filteredSupportedDistricts.length;
    const chunkSize = 20;
    const numQueries = Math.ceil(numDistricts / chunkSize);
    const districtChunks = Array.from({ length: numQueries }, (_, i) => {
      return filteredSupportedDistricts.slice(i * chunkSize, (i + 1) * chunkSize);
    });

    const districtData = await Promise.all(
      districtChunks.map(async (chunk) => {
        const query = { $limit: chunkSize, _id: chunk };
        const districts = await feathers
          .getService('districts')
          .find({ query });
        return districts.data;
      })
    );

    const districtDataSorted = districtData.flat().sort((a, b) => {
      return orderOfID.indexOf(a._id) - orderOfID.indexOf(b._id);
    });

    setSupportedDistrictsFull(districtDataSorted);

    /* chunk out loaded tasks */
    const numDistrictsLoaded = districtData.flat().length;
    const tasksNumQueries = Math.ceil(numDistrictsLoaded / chunkSize);
    const tasksChunks = Array.from({ length: tasksNumQueries }, (_, i) => {
      return districtData.flat().slice(i * chunkSize, (i + 1) * chunkSize).map(d => d._id);
    })

    const tasksData = await Promise.all(
      tasksChunks.map(async (chunk) => {
        const query = { 
          $limit: chunkSize, 
          type: 'identify-races', 
          'details.district': chunk, 
          'details.election': electionKey,
          status: ['in-progress', 'not-started'], 
          $includeCheckedOut: true 
        };
        const tasks = await feathers
          .getService('research-tasks')
          .find({ query });
        return tasks.data;
      })
    );

    console.log(`Loaded ${tasksData.flat().length} tasks for ${numDistrictsLoaded} districts`);
    
    // turn into a dictionary from district id to task
    const tasksForDistricts = tasksData.flat().reduce((acc, v) => {
      acc[v.details.district] = v;
      return acc;
    }, {});
    setTasksForDistricts(tasksForDistricts);

    setLoading(false);
  };

  useEffect(() => {
    if (feathers) loadDistricts();
  }, [feathers, statusFilter, typeFilter, pageNumber]);

  return (
    <Wrapper style={style} className={className}>
      <Typography
        variant='body2'
        style={{ gridColumn: '1 / 2', gridRow: `1 / 2`, fontWeight: 'bold', marginBottom: '4px' }}
      >
        District
      </Typography>
      <Typography
        variant='body2'
        style={{ gridColumn: '2 / 3', gridRow: `1 / 2`, fontWeight: 'bold', marginBottom: '4px' }}
      >
        Status
      </Typography>
      <Typography
        variant='body2'
        style={{ gridColumn: '3 / 4', gridRow: `1 / 2`, fontWeight: 'bold', marginBottom: '4px' }}
      >
        Analytics
      </Typography>
      <div style={{ gridColumn: '4 / 5', gridRow: `1 / 2` }}></div>
      {loading &&
        [1, 2, 3].map((i) => (
          <React.Fragment key={i}>
            <Separator
              style={{ gridColumn: '1 / -1', gridRow: `${i + 1} / ${i + 2}` }}
            />
            <Cell
              style={{ gridColumn: '1 / 2', gridRow: `${i + 1} / ${i + 2}` }}
            >
              <Skeleton variant='text' width={150} />
            </Cell>
            <Cell
              style={{ gridColumn: '2 / 3', gridRow: `${i + 1} / ${i + 2}` }}
            >
              <Skeleton variant='text' width={150} />
            </Cell>
          </React.Fragment>
        ))}
      {!loading &&
        supportedDistrictsFull.map((district, idx) => {
          const i = idx + 1;
          const statusesForDistrict = districtStatusesById[district._id];
          const isMeasuresDistrict = Boolean(statusesForDistrict?.find(v => v.contentType === 'measures'));
          const statusForDistrict = statusesForDistrict?.find(v => v.contentType === 'races');
          const measuresComplete = statusesForDistrict?.find(v => v.contentType === 'measures')?.status !== 'incomplete';
          const taskForDistrict = tasksForDistricts[district._id];
          const expectedReadership = statusForDistrict?.expectedReadership ?? 0;
          if(district.longName === 'State of Texas') {
            console.log(statusesForDistrict)
          }
          const numViews = statusesForDistrict.reduce((acc, v) => acc + (v?.analytics?.views ?? 0), 0);
          const numUsers = statusesForDistrict.reduce((acc, v) => acc + (v?.analytics?.onBallot ?? 0), 0);

          let icon = null,
            detailMessage = null,
            statusWritten = null;
          if (!statusForDistrict) {
            // error icon
            icon = <Error style={{ color: 'red' }} />;
            statusWritten = 'Error';
          } else if (statusForDistrict?.status === 'complete' && measuresComplete) {
            icon = <DoneAll style={{ color: '#4CAF50' }} />;
            const checkedBy =
              statusForDistrict?.automatedService || statusForDistrict.user?.name || statusForDistrict.user?.firstName;
            const checkedAt = moment(statusForDistrict.checkedAt).fromNow();
            detailMessage = `Checked by ${checkedBy || 'unknown'} ${
              checkedAt ? ` ${checkedAt}` : ''
            }`
            statusWritten = 'Complete (races and measures)';
          } else if (statusForDistrict?.status === 'complete' && !measuresComplete) {
            icon = <Done style={{ color: '#4CAF50' }} />;
            const checkedBy =
              statusForDistrict?.automatedService || statusForDistrict.user?.name || statusForDistrict.user?.firstName;
            const checkedAt = moment(statusForDistrict.checkedAt).fromNow();
            detailMessage = `Checked by ${checkedBy || 'unknown'} ${
              checkedAt ? ` ${checkedAt}` : ''
            }`
            statusWritten = 'Races complete, measures incomplete';
          } else if(taskForDistrict) {
            if(taskForDistrict.status === 'in-progress') {
              // a loading icon
              icon = <InProgressIcon style={{ opacity: 0.7 }} />
              statusWritten = 'In progress';
              detailMessage = `Started by ${taskForDistrict.checkedOutByUser?.name || taskForDistrict?.checkedOutByUser?.firstName || 'unknown'} ` +
                `${moment(taskForDistrict.checkedOutAt).fromNow()}`
            } else {
              icon = <div />;
              statusWritten = 'Incomplete';
            }
          } else {
            // an incomplete icon
            icon = <div />;
            statusWritten = 'Incomplete';
            detailMessage = isMeasuresDistrict ? `No measures or races scouted` : `No races scouted`
          }


          return (
            <React.Fragment key={i}>
              <Separator
                style={{ gridColumn: '1 / -1', gridRow: `${i + 1} / ${i + 2}` }}
              />
              <Cell
                style={{ gridColumn: '1 / 2', gridRow: `${i + 1} / ${i + 2}` }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', gap: '2px'}}>
                  <Typography variant='body1'><b>{district.longName}</b></Typography>
                  {
                    expectedReadership
                    ? <Typography variant='body2' style={{ opacity: 0.8, fontSize: '14px' }}>Expected readers: {(expectedReadership ?? 0).toLocaleString()}</Typography>
                    : <Typography variant='body2' style={{ opacity: 0.8, fontSize: '14px' }}>Population: {(district.population ?? 0).toLocaleString()}</Typography>
                  }
                </div>
              </Cell>
              <Cell
                style={{
                  gridColumn: '2 / 3',
                  gridRow: `${i + 1} / ${i + 2}`,
                  gap: '4px',
                }}
              >
                <div style={{ display: 'flex', gap: '4px' }}>
                  {icon}
                  <Typography variant='body1'>{statusWritten}</Typography>
                </div>
                {(detailMessage) && (
                  <Typography
                    variant='caption'
                    style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                  >
                    {detailMessage}
                  </Typography>
                )}
              </Cell>
              <Cell
                style={{
                  gridColumn: '3 / 4',
                  gridRow: `${i + 1} / ${i + 2}`,
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '16px',
                }}
              >
                <Tooltip title='Number of users within the district'>
                  <AnalyticsCell>
                    <UsersIcon style={{ fontSize: '15px' }}/>
                    <Typography variant='body1'>{`${numUsers ?? 0}`.toLocaleString()}</Typography>
                  </AnalyticsCell>
                </Tooltip>
                <Tooltip title='Number of content views for this district'>
                  <AnalyticsCell>
                    <ViewsIcon style={{ fontSize: '15px' }}/>
                    <Typography variant='body1'>{`${numViews ?? 0}`.toLocaleString()}</Typography>
                  </AnalyticsCell>
                </Tooltip>
              </Cell>
              <Cell
                style={{
                  gridColumn: '4 / 5',
                  gridRow: `${i + 1} / ${i + 2}`,
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  gap: '8px',
                }}
              >
                {
                  (statusForDistrict?.notes?.length > 0) && 
                  <Tooltip title='View notes'>
                    <IconButton
                      size='small'
                      onClick={(e) => { console.log('view notes', statusForDistrict.notes) }}
                    >
                      <NoteIcon />
                    </IconButton>
                  </Tooltip>
                }
                {
                  statusForDistrict?.source && 
                  <Tooltip title='View source'>
                    <a href={statusForDistrict.source} target='_blank' rel='noreferrer'>
                      <IconButton
                        size='small'

                      >
                        <CodeIcon />
                      </IconButton>
                    </a>
                  </Tooltip>
                }
                <Tooltip title='Scout ballot items'>
                  <IconButton
                    size='small'
                    component={Link}
                    to={
                      (taskForDistrict && !['cancelled', 'complete'].includes(taskForDistrict.status)) 
                      ? `/tasks/identify-races?district=${district._id}`
                      : `/elections/${electionKey}/scout-ballots/${district._id}`
                    }
                  >
                    <SearchIcon size='small' />
                  </IconButton>
                </Tooltip>
              </Cell>
            </React.Fragment>
          );
        })}
        {
          !loading &&
          <div style={{ gridColumn: '1 / -1', gridRow: `${(supportedDistrictsFull || []).length + 2} / ${(supportedDistrictsFull || []).length + 3}`, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            {/* create a pagination component using Material ui*/}
            <Pagination
              count={Math.ceil(count / numPerPage)}
              page={pageNumber}
              onChange={(e, newPage) => onChangePageNumber(newPage)}
              color="primary"
            />
          </div>
        }
    </Wrapper>
  );
};



const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 0.5fr auto;
  align-items: center;
`;

const Separator = styled.div`
  width: calc(100% + 24px * 2);
  margin-left: -24px;
  height: 1px;
  background-color: #e0e0e0;
  align-self: start;
`;

const Cell = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 0;
`;

const AnalyticsCell = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`

export { SupportedDistrictList };
