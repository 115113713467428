import React, { Component, useEffect, useState, useMemo } from 'react';
import { useFeathers, isPermitted } from '../../app/util';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import {
  CTAButton,
  LoadingSpinner,
  BackButton,
  DataDetailToolbar,
  PreviewButton,
  DataField,
  EditOfficeField,
  TranslationPairDownload,
  LanguageSelectButton
 } from '../../components'
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import superagent from 'superagent';
import { stateCodeForName } from '@branchpolitics/microservice.clean.states';


const RaceDetail = () => {
  const feathers = useFeathers();
  const user = useSelector(state => state.user);
  const { key } = useParams();
  const [ districtTypeConfig, setDistrictTypeConfig ] = useState(null);

  /* handle data loading */
  const [ activeLanguage, setActiveLanguage ] = useState('en');
  const [ officeData, setOfficeData ] = useState(null);
  useEffect(() => {
    if(feathers) loadOfficeData(activeLanguage)
  }, [ feathers, activeLanguage ])
  const loadOfficeData = async (ln) => {
    try {
      const res = await feathers.getService('offices').get(key, {
        query: { ln }
      });
      console.log(res)
      setOfficeData(res);
    } catch(err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if(officeData) {
      fetchDistrictTypes()
    }
  }, [ officeData ])

  const fetchDistrictTypes = async () => {
    const state = officeData?.district;
    if(state.type !== 'state') return;

    const stateCode = stateCodeForName(state?.name);
    if(!stateCode)  return;

    // use superagent to request 
    const response = await superagent.get('https://districts-microservice.herokuapp.com/api/v1/district-configurations/' + stateCode.toLowerCase());
    const { body } = response;
    setDistrictTypeConfig(body);
  }

  // use memo for district type, flatten for state
  const districtTypesForState = useMemo(() => {
    if(!districtTypeConfig) return;
    const { types, optionalTypes } = districtTypeConfig;
    const typesAll = [... new Set([ ...Object.values(types).flat(), ...(optionalTypes || []) ])];
    return typesAll
  }, [ districtTypeConfig ])

  if(!officeData) {
    return (
      <Wrapper>
        <Section style={{ marginBottom: '20px' }}>
          <BackButton onClick={{ to: `/offices` }} />
        </Section>
        <EmptyStateWrapper>
          <LoadingSpinner />
        </EmptyStateWrapper>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <DataDetailToolbar
        onBack={{ to: `/offices` }}
        title={officeData.name}
        titleSecondary={officeData?.nameEn ? `(${officeData?.nameEn})` : ``}
        navTree={[
          { text: 'Offices', to: `/offices` },
          { text: officeData.name }
        ]}
        actionButtonsComponent={
          <ButtonContainer>
            <LanguageSelectButton
              style={{ marginRight: '4px' }}
              selected={activeLanguage}
              onSelect={setActiveLanguage}
            />
            <TranslationPairDownload serviceName={'offices'} objectId={officeData?.key}/>
          </ButtonContainer>
        }
      />
      <DataRow>
        <EditOfficeField
          editingDisabled={activeLanguage !== 'en'}
          type='title'
          officeId={officeData.key}
          value={officeData.name}
          style={{ width: '40%' }}
          onChange={setOfficeData}
        />
      </DataRow>
      <DataRow>
        <EditOfficeField
          editingDisabled={activeLanguage !== 'en'}
          type='issuesToCover'
          officeId={officeData.key}
          value={officeData.issuesToCover}
          style={{ width: '100%' }}
          onChange={setOfficeData}
        />
      </DataRow>
      <DataRow>
        <EditOfficeField
          type='districtTypes'
          officeId={officeData.key}
          value={officeData.districtTypes}
          style={{ width: '100%' }}
          onChange={setOfficeData}
          districtTypesForState={districtTypesForState}
        />
      </DataRow>
      <DataRow>
        <EditOfficeField
          editingDisabled={activeLanguage !== 'en'}
          type='short'
          officeId={officeData.key}
          value={officeData.descriptionShort}
          style={{ width: '100%' }}
          onChange={setOfficeData}
        />
      </DataRow>
      <DataRow>
        <EditOfficeField
          editingDisabled={activeLanguage !== 'en'}
          type='long'
          officeId={officeData.key}
          value={officeData.descriptionLong}
          style={{ width: '100%' }}
          onChange={setOfficeData}
        />
      </DataRow>
      <DataRow>
        <EditOfficeField
          editingDisabled={activeLanguage !== 'en'}
          type='impactIssues'
          officeId={officeData.key}
          value={officeData.impactIssues}
          style={{ width: '100%' }}
          onChange={setOfficeData}
        />
      </DataRow>
      <ReactTooltip place='left' effect='solid' type='light'/>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: calc(100% - 24px - 36px);
  display: flex;
  flex-direction: column;
  padding: 36px 36px 36px 24px;
  align-items: stretch;
`;

const Section = styled.div`
  margin: 0 0 48px;
  width: 100%;
  display: flex;
  h1 {
    font-size: 24px;
    margin: 0 0 0;
  }
`
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: -10px;

  div {
    margin: 0 0 0 0;
  }
`

const OfficeDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 24px;
  margin-bottom: 12px;
  color: ${props => props.theme.colors.darkPurple};

  h1 {
    font-size: 28px;
    margin: 0 0 16px;
  }
`

const DataRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
`

const EmptyStateWrapper = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    ${({ theme }) => theme.font.normal};
    opacity: 0.8;
    font-size: 16px;
  }
`;

export default RaceDetail;
