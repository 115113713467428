import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import {
  TaskPage,
} from "../../components";
import styled from "styled-components";
import { ScoutRacesForArea } from "../../components/ScoutRacesForArea";

const IdentifyRaces = () => {
  const [task, setTask] = useState(null);
  const [error, setError] = useState(false);
  const [completionData, setCompletionData] = useState({})
  const [saving, setSaving] = useState(false);


  return (
    <Wrapper>
      <TaskPage
        taskType='identify-races'
        allowMultipleCheckedOutTasks
        errorMessage={error?.message}
        holdingData={completionData}
        onCheckoutTask={(task) => setTask(task)}
        childError={error}
        completionData={completionData}
        onChangeSaving={(saving) => setSaving(saving)}
        noMoreTasksMessage="There are currently no districts to identify races for. Try back later."
        task={task}
      >
        {
          !task &&
          <Typography variant='body1'>Click a task on the left side pane to get started.</Typography>
        }
        {
          task &&
          <ScoutRacesForArea
            primaryDistrictId={task.details.district}
            election={task.details.election}
            onChangeCompletionData={(data) => setCompletionData(data)}
            disabled={saving}
            onError={(error) => setError(error)}
          />
        }
      </TaskPage>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
`;



export default IdentifyRaces;
