import React, { Component, useEffect, useState, useMemo } from 'react';
import { useFeathers, isPermitted, usePrevious } from '../../app/util';
import { useSelector } from 'react-redux';
import {
  Link,
  Redirect,
  useRouteMatch,
  useParams,
  useHistory,
  useLocation,
} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import {
  CTAButton,
  RacePreviewTile,
  PaginationControl,
  PaginationStatus,
  AddButton,
  LoadingSpinner,
  Modal,
  CreateRaceModal,
  SearchableSelectInput, PartyTag, CoverageTag, TranslationPairDownload, DataDetailToolbar
} from '../../components';
import styled from 'styled-components';
import qs from 'qs';
import moment from 'moment';
import ArrowUp from '@material-ui/icons/ArrowUpward';
import ArrowDown from '@material-ui/icons/ArrowDownward';
import IconButton from "@material-ui/core/IconButton";
import PreviewIcon from "@material-ui/icons/Visibility";

const Wrapper = styled.div`
  width: calc(100% - 2 * 24px);
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: stretch;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;

  h1 {
    font-size: 28px;
    margin: 0;
  }
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 8px;
  border: 1px #EEEEEE solid;
  overflow: hidden;
`;

const LoadingTile = styled.div`
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;

  span {
    marginBottom: 20px;
  }
`;


const Races = () => {
  const feathers = useFeathers();
  const election = useParams().key;
  const route = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const user = useSelector(state => state.user);
  const electionData = useSelector(state => state?.elections?.byKey[election])
  const isPartisan = electionData?.partisan;
  const [ races, setRaces ] = useState(null);
  const [ numRaces, setNumRaces ] = useState(0);
  const [ searchTerm, setSearchTerm ] = useState('');
  const searchTermPrev = usePrevious(searchTerm)
  const [ loading, setLoading ] = useState(false);
  const numRacesPerPage = 10;

  const qualifyingPassed = electionData?.qualifyingDate
    ? moment().isAfter(moment.utc(electionData.qualifyingDate).add(1, 'day'))
    : true;

  /* handle filter controls for data */
  const {
    page,
    sFilters,
    sortBy
  } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const activePage = page ? parseInt(page) : 0;
  const filters = sFilters ? JSON.parse(sFilters) : [];
  const sortFilters = sortBy ? JSON.parse(sortBy) : [];

  const filtersPrev = usePrevious(filters);
  const activePagePrev = usePrevious(activePage);
  const sortByPrev = usePrevious(sortBy);
  const [ filterMenuOpen, setFilterMenuOpen ] = useState(null);
  const [ filterAdding, setFilterAdding ] = useState(null);
  const [ searchOptions, setSearchOptions ] = useState([]);
  const [ searchLoading, setSearchLoading ] = useState(false);

  const descendantOf = useMemo(() => {
    return electionData?.districts?.map((d) => d._id)
  }, [electionData])

  const searchForField = async (searchText = '') => {
    if(!feathers) return;

    setSearchLoading(true)
    let options = [];
    if(filterAdding === 'district') {
      const district = await feathers.getService('districts').find({ query: {
        searchTerm: { $search: searchText },
        ...(descendantOf ? { $descendantOf: descendantOf, $nestChildren: false, $includeAncestor: true } : {}),
      }})
      setSearchOptions(
        district.data.map(d => ({
          ...d,
          text: d.longName,
        }))
      );
    } else if(filterAdding === 'city') {
      const district = await feathers.getService('districts').find({ query: {
        searchTerm: { $search: searchText },
        ...(descendantOf ? { $descendantOf: descendantOf, $nestChildren: false, $includeAncestor: true } : {}),
        type: 'city'
      }})
      setSearchOptions(
        district.data.map(d => ({
          ...d,
          text: d.longName,
        }))
      );
    } else if(filterAdding === 'county') {
      const district = await feathers.getService('districts').find({ query: {
        searchTerm: { $search: searchText },
        ...(descendantOf ? { $descendantOf: descendantOf, $nestChildren: false, $includeAncestor: true } : {}),
        type: 'county'
      }})
      setSearchOptions(
        district.data.map(d => ({
          ...d,
          text: d.longName,
        }))
      );
    } else if(filterAdding === 'office') {
      const offices = await feathers.getService('offices').find({ query: {
        name: { $search: searchText },
        ...(descendantOf ? { district: { $within: descendantOf }} : {})
      }})
      setSearchOptions(
        offices.data.map(o => ({
          ...o,
          text: o.name,
        }))
      );
    } else if(filterAdding === 'party') {
      const parties = [
        { key: 'D', text: 'Democrat' },
        { key: 'R', text: 'Republican' },
        { key: 'N', text: 'Nonpartisan' }
      ].filter(p => p.text.toUpperCase().startsWith((searchText || '').toUpperCase()))

      setSearchOptions(parties);
    } else if(filterAdding === 'priorityLevel') {
      const priorityLevels = [
        { key: 'high', text: 'High' },
        { key: 'medium', text: 'Medium' },
        { key: 'low', text: 'Low' }
      ].filter(p => p.text.toUpperCase().startsWith((searchText || '').toUpperCase()))

      setSearchOptions(priorityLevels);
    } else if(filterAdding === 'status') {
      const options = [
        { key: 'future-coverage', text: 'In progress' },
        { key: 'no-coverage', text: 'No coverage' },
        { key: 'uncontested', text: 'Uncontested' },
        { key: 'coverage', text: 'Live' }
      ].filter(p => p.text.toUpperCase().startsWith((searchText || '').toUpperCase()))

      setSearchOptions(options);
    } else if (filterAdding === 'workflow') {
      const options = [
        { key: 'draft', text: 'Draft'},
        { key: 'referencesAdded', text: 'Auto coverage, awaiting first draft' },
        { key: 'autoProfileCreated', text: 'Auto coverage, awaiting review' },
        { key: 'autoProfileReviewed', text: 'Auto coverage, awaiting publish' },
        { key: 'researcherSubmitted', text: 'Researcher Submitted - Needs Review', 'rejection': false },
        { key: 'researcherSubmitted', text: 'Needs Researcher Revision', 'rejection': true },
        { key: 'reviewerSubmitted', text: 'Reviewer Submitted - Needs Publisher Review', rejection: false },
        { key: 'reviewerSubmitted', text: 'Needs Reviewer Revision', rejection: true },
        { key: 'publisherSubmitted', text: 'Ready To Publish', rejection: false },
        { key: 'needsReview', text: 'Unpublished Changes'},
      ].filter(p => p.text.toUpperCase().startsWith((searchText || '').toUpperCase()))
      setSearchOptions(options);
    }
    setSearchLoading(false)
  }

  const onFilterOptionSelect = async e => {
    if(filterAdding === 'district') {
      const { _id, longName } = e.target.value;
      addFilter({
        label: longName,
        query: { district: _id }
      })
    } else if (filterAdding === 'city' || filterAdding === 'county') {
      const { name, _id } = e.target.value;
      // get all ditricts with parent of `name`
      const res = await feathers.getService('districts').find({ query: {
        parent: name,
        $limit: 50
      }});
      const subdistricts = res.data.map(d => d._id);
      addFilter({
        label: `In ${name}`,
        query: { district: [].concat([ _id ], subdistricts) }
      })
    } else if(filterAdding === 'office') {
      const { key, name } = e.target.value;
      addFilter({
        label: name,
        query: { office: key }
      })
    } else if(filterAdding === 'party') {
      const { key, text } = e.target.value;
      addFilter({
        label: text,
        query: { party: key }
      })
    } else if(filterAdding === 'priorityLevel') {
      const { key, text } = e.target.value;
      addFilter({
        label: `${text} priority`,
        query: { priorityLevel: key }
      })
    } else if(filterAdding === 'status') {
      const { key, text } = e.target.value;
      let queryMapped;
      if(key === 'future-coverage') queryMapped = { coverageStatus: 'no-coverage', willCover: true };
      else if(key === 'no-coverage') queryMapped = { coverageStatus: 'no-coverage', willCover: false };
      else if(key === 'coverage' || key === 'uncontested') queryMapped = { coverageStatus: key };
      addFilter({
        label: text,
        query: queryMapped
      })
    } else if (filterAdding === 'workflow') {
      const { key, text, rejection } = e.target.value
      if (key === 'needsReview') {
        addFilter({
          label: text,
          query: {
            synced: false,
          }
        })
      } else {
        addFilter({
          label: text,
          query: {
            synced: false,
            [`workflows.${key}`]: rejection ? 'rejected' : 'ready'
          }
        })
      }
    } 
  }

  const addFilter = toAdd => {
    let key;
    if(toAdd.query?.district) key = 'district';
    else if(toAdd.query?.office) key = 'office';
    else if(toAdd.query?.party) key='party';
    else if(toAdd.query?.coverageStatus) key=['coverageStatus', 'willCover'];
    else if(typeof(toAdd.query?.synced) !== 'undefined') key='synced';
    else if(toAdd.query?.priorityLevel) key='priorityLevel';
    else if(toAdd.query?.workflow) key='workflow';
    else if(toAdd.query?.runoff) key='runoff';

    const filtersToKeep = key === 'synced'
      ? filters.slice()
      : filters.filter(f => Array.isArray(key) ? true : f.query[key] || typeof(f.query?.synced) !== 'undefined');
    const filtersNew = [].concat(filtersToKeep, [ toAdd ]);
    updatePageQuery(0, filtersNew, sortFilters)
    setFilterAdding(null);
  }
  const removeFilter = toRemove => {
    const newFilters = filters.filter(f => f.label !== toRemove.label)
    updatePageQuery(0, newFilters, sortFilters)
  }

  const updatePageQuery = (page, filters, sortBy) => {
    const searchTermNew = qs.stringify({
      ...(page ? { page } : {}),
      ...(filters?.length ? { sFilters: JSON.stringify(filters) } : {}),
      ...(sortBy?.length ? { sortBy: JSON.stringify(sortBy) } : {})
    })

    history.push(location.pathname + '?' + searchTermNew)
  }

  const sortByField = (fieldName) => {
    const existingFilterForField = (sortFilters || []).some(s => s.field === fieldName);
    console.log(`existingFilterForField`, existingFilterForField)
    const sortFiltersNew = (sortFilters || []).map(s => {
      if(s.field === fieldName) {
        return { field: fieldName, ascending: s?.ascending === 1 ? -1 : 0 };
      } else {
        return s;
      }
    }).filter(v => v?.ascending !== 0);
    console.log(`sortFiltersNew`, sortFiltersNew)
    const sortFiltersFinal = existingFilterForField ? sortFiltersNew.slice() : [].concat(sortFiltersNew, { field: fieldName, ascending: 1 });
    updatePageQuery(0, filters, sortFiltersFinal)
  }

  const getRaces = async (election, page = 0, searchFor, filters = [], sortFilters) => {
    setLoading(true);
    let term = searchFor || searchTerm;
    const filterQueries = filters.reduce((acc, val) => ({
      ...acc,
      ...val.query
    }), {});
    const $sort = sortFilters?.length > 0
      ? sortFilters.reduce((acc, val) => {
          const update = {};
          update[val.field] = val.ascending;
          return { ...acc, ...update}
        }, {})
      : { ballotOrder: 1, expectedReaders: -1, createdAt: 1 };
    const query = {
      election,
      $depopulate: ['candidates'],
      $skip: page*numRacesPerPage,
      $limit: numRacesPerPage,
      ...(term
        ? { $or: [ { candidatesSearchTerm: { $search: term } }, { officeSearchTerm: { $search: term } } ] }
        : {}
      ),
      $sort,
      ...filterQueries
    }
    const races = await feathers.getService('races').find({ query });
    setRaces(races?.data || [])
    setNumRaces(races?.total);
    setLoading(false)
  }
  useEffect(() => {
    if(feathers) {
      if(races === null && !loading) {
        getRaces(election, activePage, searchTerm, filters, sortFilters)
      } else if(activePage !== activePagePrev ||
        filtersPrev?.length !== filters.length ||
        filters.some(f => !(filtersPrev || []).map(p => p.label).includes(f.label)) ||
        (filtersPrev || []).some(p => !filters.map(f => f.label).includes(p.label)) ||
        searchTerm !== searchTermPrev ||
        sortByPrev !== sortBy
      ) {
        getRaces(election, activePage, searchTerm, filters, sortFilters)
      }
    }
  }, [ feathers, searchTerm, activePage, election, filters, races, loading ])

  return (
    <Wrapper>
      {
        route.path.includes('/new') &&
        (
          <Modal onClose={() => history.goBack()}>
            <CreateRaceModal
              onClose={() => history.goBack()}
              election={electionData}
              redirectOnFinish={true}
            />
          </Modal>
        )
      }
      <DataDetailToolbar
        onBack={{ to: {
            pathname: `/elections/${election}`
          }}}
        navTree={[
          {
            text: 'Elections',
            to: {
              pathname: `/elections`,
            }
          },
          {
            text: electionData?.name || 'Election',
            to: {
              pathname: `/elections/${election}`,
            }
          },
          { text: 'Races' }
        ]}
        actionButtonsComponent={
          isPermitted(user, ['editor', 'admin', 'researcher', 'super-admin', 'reviewer', 'publisher'])
            ? <CTAButton onClick={{ to: 'races/new' }} value='New Race' mode='pill'/>
            : <div/>
        }
      />
      <Row style={{ justifyContent: 'space-between' }}>
        <Row style={{ justifyContent: 'flex-start', flexWrap: 'wrap' }}>
          {
            filters.length > 0 &&
            filters.map((f, i) =>
              <Chip
                style={{ marginRight: '8px', marginBottom: '4px', color: '#FFFFFF' }}
                color='secondary'
                key={i}
                label={<Typography style={{ color: '#FFFFFF', fontWeight: 'bold' }}>{f.label}</Typography>}
                onDelete={() => removeFilter(f)}/>
            )
          }
          {
            Boolean(filterAdding) &&
            <SearchableSelectInput
              name='filterSearch'
              onChangeSearchText={searchForField}
              value={null}
              onChange={onFilterOptionSelect}
              optionsLoading={searchLoading}
              options={searchOptions || []}
              onCancel={() => setFilterAdding(null)}
              style={{ marginRight: '8px', marginBottom: '4px' }}
            />
          }
          <Button
            onClick={e => setFilterMenuOpen(e.currentTarget)}
            startIcon={<AddIcon/>}
          >
            Filter
          </Button>
          <Menu
            anchorEl={filterMenuOpen}
            keepMounted
            open={Boolean(filterMenuOpen)}
            onClose={() => setFilterMenuOpen(null)}
          >
            <MenuItem
              onClick={() => {
                setFilterAdding('county')
                setFilterMenuOpen(null)
              }}
            >
              County
            </MenuItem>
            <MenuItem
              onClick={() => {
                setFilterAdding('city')
                setFilterMenuOpen(null)
              }}
            >
              City
            </MenuItem>
            <MenuItem
              onClick={() => {
                setFilterAdding('priorityLevel')
                setFilterMenuOpen(null)
              }}
            >
              Priority level
            </MenuItem>
            <MenuItem
              onClick={() => {
                setFilterAdding('district')
                setFilterMenuOpen(null)
              }}
            >
              District
            </MenuItem>
            <MenuItem
              onClick={() => {
                setFilterAdding('office')
                setFilterMenuOpen(null)
              }}
            >
              Office type
            </MenuItem>
            <MenuItem
              onClick={() => {
                setFilterAdding('status')
                setFilterMenuOpen(null)
              }}
            >
              Coverage Status
            </MenuItem>
            {
              isPartisan &&
              <MenuItem
                onClick={() => {
                  setFilterAdding('party')
                  setFilterMenuOpen(null)
                }}
              >
                Party
              </MenuItem>
            }
            <MenuItem onClick={() => {
              setFilterAdding('workflow')
              setFilterMenuOpen(null)
            }}>
              Workflow Status
            </MenuItem>
            <MenuItem onClick={() => {
              addFilter({
                label: 'Going to Runoff',
                query: { 'resultSummary.goingToRunoff': true }
              })
              setFilterMenuOpen(null)
            }}>
              Going to runoff
            </MenuItem>


          </Menu>
        </Row>
        <TextField
          mode='condensed'
          onChange={(e) => setSearchTerm(e.target.value)}
          name='search-filter'
          value={searchTerm || ''}
          placeholder='Search...'
        />
      </Row>

      <HeaderRow>
        <Typography variant='h5'>Title</Typography>
        <Typography variant='h5'>Candidates</Typography>
        {
          <HeaderRowItem
            sortFieldName='informationLevel'
            sortFilters={sortFilters}
            sortFunction={sortByField}
          >
            Status
          </HeaderRowItem>
        }
        <HeaderRowItem
          sortFieldName='expectedReaders'
          sortFilters={sortFilters}
          sortFunction={sortByField}
        >
          Expected Readers
        </HeaderRowItem>
        <HeaderRowItem
          sortFieldName='analytics.views'
          sortFilters={sortFilters}
          sortFunction={sortByField}
        >
          # Views
        </HeaderRowItem>
        <Typography variant='h5'></Typography>
      </HeaderRow>
      <Content>

        {
          loading
          ?
            <LoadingTile>
              <span>Searching races...</span>
              <LoadingSpinner scale={0.8}/>
            </LoadingTile>
          :
            (races || []).map((race, idx) => (
              <RacePreviewTile
                qualifyingPassed={qualifyingPassed}
                party={isPartisan ? race.party : null}
                title={race.officeName}
                resultSummary={race.resultSummary}
                showResultsLinked={(electionData?.status || '').includes('results')}
                synced={race.synced}
                coverageStatus={race.coverageStatus}
                willCover={race.willCover}
                updatedAt={race.updatedAt}
                office={race.office.name}
                district={race?.originalDistrict?.longName || race.district?.longName}
                expectedReaders={race?.expectedReaders}
                priorityLevel={race?.priorityLevel}
                numVoters={race.analytics?.onBallot ?? 0}
                numViews={race.analytics?.views ?? 0}
                progress={0.2}
                to={{
                  pathname: `races/${race._id}`,
                  ...( location.search?.length > 0
                    ? { state: { racesSearch: location.search }}
                    : {}
                  )
                }}
                candidateSummary={race.candidateSummary}
                key={race._id}
              />)
            )
        }
      </Content>
      <Row>
        <PaginationStatus
          activePage={activePage + 1}
          items={numRaces}
          itemsPerPage={numRacesPerPage}
          itemName='race'
        />
        <PaginationControl
          activePage={activePage + 1}
          items={numRaces}
          itemsPerPage={numRacesPerPage}
          onChange={(pg) => updatePageQuery(pg - 1, filters, sortFilters)}
        />
      </Row>
    </Wrapper>
  )
}

const HeaderRow = styled.div`
  width: calc(100% - 24px * 2);
  margin: 12px 24px 4px;
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 70px 70px 25px;
  grid-gap: 24px;
  align-items: end;
`

const HeaderRowItem = ({
  sortFieldName,
  sortFilters,
  sortFunction,
  children
}) => {
  const active = (sortFilters || []).some(f => f?.field === sortFieldName);
  const ascending = active && sortFilters.filter(f => f?.field === sortFieldName)[0].ascending === 1;
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        ...(sortFunction ? { cursor: 'pointer' } : {}),
      }}
      {...(sortFunction ? { onClick: () => sortFunction(sortFieldName) } : {}) }
    >
      <Typography
        variant='h5'
        style={{
          ...(active ? { color: '#453DB7' } : {})
        }}
      >
        {children}
      </Typography>
      {
        active &&
        (ascending ? <ArrowUp style={{ height: '16px', color: '#453DB7' }}/> : <ArrowDown style={{ height: '16px', color: '#453DB7' }}/>)
      }
    </div>
  )

}

export default Races;
