import React, { useState, useEffect, useMemo } from 'react';
import Typography from '@material-ui/core/Typography';
import { useFeathers } from '../../app/util';
import {
  ManualVoteTally,
  ElectionResultsSelectInput,
  TaskPage,
  ProgressBar,
  CirclePhoto
 } from '../../components'
import styled from 'styled-components';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Link } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import { TextField } from '@material-ui/core';


// completion should be a an array of the candidates for a certain race, each of which with the fields of 
// candidate
// manual.totalVotes
// clarityId
// so...
// {
// 	candidates: [{
//    candidate: String,
//    manual: { totalVotes: Number },
//    clarityId: Number
//  }]
//  writeInVotes: Number (defalts to 0)
// }
const ElectionResults = () => {
  const feathers = useFeathers();

  const [ task, setTask ] = useState(null);
  const [ error, setError ] = useState(false);
  const [ race, setRace ] = useState(null);
  const [ saving, setSaving ] = useState(false);
  const [ completionData, setCompletionData ] = useState(null)

  const onCheckoutTask = async (task) => {
    setTask(task);
    setRace(null);
    setCompletionData(null);
    setError(null);

    // get race separately since the aggregated race object attached to candidate is missing some fields
    const race = await feathers.getService('races').get(task.details.race);
    setRace(race)
    const candidatesQualified = race.candidates.filter(c => c.qualified === 'yes');

    setCompletionData({
      candidates: candidatesQualified.map(c => ({
        candidate: c._id,
        manual: {
          totalVotes: c?.result?.manual?.totalVotes
        },
        clarityId: c?.result?.autoSources?.find(s => s.sourceType === 'clarity')?.lookupData?.id
      })),
      writeInVotes: race.writeInVotes
    })
  }

  const candidatesCompleted = completionData?.candidates?.filter(c => c.manual?.totalVotes || c?.clarityId || c?.erSearchResult).map(c => c.candidate);
  const automatedVotesEnabled = race?.election?.state?.name === 'Georgia';
  const completionDataParsed = {
    candidates: (completionData?.candidates || []).filter(c => candidatesCompleted.includes(c.candidate)).map(c => { //holdingData perhaps goes in here
      let manualVotes;
      if(c.manual.totalVotes) {
        if(typeof(c.manual.totalVotes) === 'string') {
          const valsMapped = c.manual.totalVotes.replaceAll(',', '').split('+').map(v => v.trim()).filter(Boolean).map(v => parseInt(v));
          const newVotes = valsMapped.reduce((acc, val) => acc + val, 0);
          manualVotes = { 
            manual: {
              totalVotes: newVotes
            }
          }
        } else {
          manualVotes = { 
            manual: {
              totalVotes: c.manual.totalVotes
            }
          }
        }
      }

      return {
        candidate: c.candidate,
        ...(manualVotes || {}),
        clarityId: c.clarityId || c?.erSearchResult?.clarityLookup?.clarityId
      }
    }),
    writeInVotes: completionData?.writeInVotes ? parseInt(completionData.writeInVotes) : 0
  }

  const orderMode = 'last-name';

  const candidatesToDisplay = useMemo(() => {
    const qualified = (race?.candidates || []).filter(c => c.qualified === 'yes');
    if(orderMode === 'first-name') 
      return qualified;
    else if(orderMode === 'last-name') 
      return qualified.sort((a, b) => {
        const lastNameA = a.name.split(' ').slice(-1)[0];
        const lastNameB = b.name.split(' ').slice(-1)[0];
        if(lastNameA === lastNameB) return 0;
        if(lastNameA < lastNameB) return -1;
        return 1;
      });
  }, [ race ])


  return (
    <Wrapper>
      <TaskPage
        taskType='election-results'
        errorMessage={error?.message}
        onCloseErrorMessage={setError}
        onCheckoutTask={onCheckoutTask}
        completionData={completionDataParsed}
        autoSaveHoldingData={false}
        noMoreTasksMessage={'There are currently no more candidates to match with election results. Try back later.'}
        task={task}
        onChangeSaving={setSaving}
      >
        {
          Boolean(race) &&
          <WrapperInner>
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '24px' }}>
              <div style={{ display: 'flex', gap: '36px', alignItems: 'center' }}>
                <div>
                  <div style={{ display: 'flex', gap: '4px', alignItems: 'flex-end' }}>
                    <Typography variant='h3' style={{ fontWeight: 'bold' }}>{race?.longName}</Typography>
                    <Link to={`/elections/${race.election?.key || race?.election}/races/${race?._id}`} target='_blank'>
                      <OpenInNewIcon/>
                    </Link>
                  </div>
                  <Typography variant='body1' mt={1}>{race?.district?.longName}</Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '250px'}}>
                    <ProgressBar percentage={race?.resultSummary?.numCandidates > 0 ? (candidatesCompleted.length / race?.resultSummary?.numCandidates * 100) : 0} />
                    <Typography variant='body1' style={{ opacity: 1.0, fontSize: '12px', marginTop: '2px' }}>{candidatesCompleted.length ?? 0}/{race?.resultSummary?.numCandidates} candidates linked</Typography>
                  </div>
              </div>
            </div>
            <div style={{ marginLeft: '16px', display: 'flex', flexDirection: 'column'}}>
              {
                candidatesToDisplay.map((cd, idx) => {
                  const resultForCandidate = (completionData?.candidates || []).find(c => c.candidate === cd._id);
                  const completed = candidatesCompleted.includes(cd._id);
                  return (
                    <div key={cd?._id} style={{ borderTop: idx === 0 ? '' : 'solid 1px #DDDDDD', padding: '12px 0 16px' }}>
                      <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                        <CirclePhoto size='small' photoPath={cd?.photoPathFace} checkmark={completed}/>
                        <Typography variant='h4' style={{ fontWeight: 'bold', marginLeft: '12px' }}>{cd.name}</Typography>
                        <Link to={`/elections/${cd?.election?.key || cd?.election}/races/${cd?.race._id}/candidates/${cd?._id}`} target='_blank'>
                          <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                          }}>
                            <Typography variant='body2'>Full page</Typography>
                            <OpenInNewIcon style={{ width: '15px', height: '15px' }}/>
                          </div>
                        </Link>
                      </div>
                      <div style={{ width: 'calc(100% - 69px)', marginLeft: '69px', display: 'grid', flexDirection: 'column', gridTemplateColumns: '1.5fr 1fr', gridGap: '24px', alignItems: 'center'}}>
                        {
                          automatedVotesEnabled && 
                          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px'}}>
                            <Typography variant='body1' >Automated</Typography>
                            {
                              resultForCandidate?.clarityId 
                              ? <Typography variant='body1' style={{ fontWeight: 'bold'}}>Clarity ID: {resultForCandidate.clarityId}</Typography>
                              : <ElectionResultsSelectInput
                                disabled={saving}
                                onChange={({target}) => {
                                  setCompletionData({
                                    ...completionData,
                                    candidates: completionData.candidates.map(c => c.candidate === cd._id ? {
                                      ...c,
                                      erSearchResult: target.value
                                    } : c)
                                  })
                                }}
                                value={resultForCandidate?.erSearchResult}
                                candidateId={cd?._id}
                              />

                            }
                            
                          </div>
                        }
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px'}}>
                          <Typography variant='body1' >Manual</Typography>
                          <TextField
                            value={resultForCandidate?.manual?.totalVotes || ''}
                            onChange={(e) => {
                              setCompletionData({
                                ...completionData,
                                candidates: completionData.candidates.map(c => c.candidate === cd._id ? {
                                  ...c,
                                  manual: {
                                    totalVotes: e.target.value
                                  }
                                } : c)
                              })
                            }}
                            disabled={saving}
                            variant='outlined'
                            size='small'
                          />
                        </div>
                      </div>
                    </div>
                  )
                })
              }
              <div style={{ borderTop:  'solid 1px #DDDDDD', padding: '18px 0 16px 16px' }}>
                <div style={{ display: 'flex', gap: '4px', marginBottom: '6px', alignItems: 'center' }}>
                  <Typography variant='body1' style={{ fontWeight: 'bold' }}>Write-in votes for the entire race</Typography>
                </div>
                <TextField
                  value={completionData?.writeInVotes || ''}
                  onChange={(e) => {
                    setCompletionData({
                      ...completionData,
                      writeInVotes: e.target.value
                    })
                  }}
                  disabled={saving}
                  variant='outlined'
                  size='small'
                />
              </div>
            </div>
          </WrapperInner>
        }
        {
          !Boolean(race) &&
          <WrapperInner>
            <Skeleton variant="text" width={150}/>
            <Skeleton variant="text" width={200}/>
            <Skeleton variant="text" width={100}/>
          </WrapperInner>
        }
      </TaskPage>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: calc(100% - 24px - 36px);
  display: flex;
  flex-direction: column;
  padding: 36px 36px 36px 24px;
  align-items: stretch;
`;

const WrapperInner = styled.div`
  max-width: 700px;
  display: flex;
  flex-direction: column;

  align-items: stretch;
`

export default ElectionResults;
