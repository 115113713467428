
import { Button, IconButton, MenuList, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { IssueDropdown } from '../IssueDropdown';
import styled from 'styled-components';
import { StarRanker } from '../StarRanker';
import { RedundantFlag } from '../RedundantFlag';
import ReactTooltip from 'react-tooltip';
import RemoveIcon from '@material-ui/icons/Close';
import { SourceList } from '../lower-order';
import { AddSource } from '../AddSource';
import { InputListCondensed } from '../InputListCondensed';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ContentCopy from '@material-ui/icons/FileCopyOutlined';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';

const EditStance = ({
  issueKey,    // the key of the issue
  issueOptions, // the options for the issue dropdown
  onReassignIssue, // the function to call when the issue is reassigned
  isFromProfiler,
  active,
  onFocus,
  value, // { autoSummary, complete, createdAt, directQuote, editsMade, issuesSecondary, hasError (can take of values of 'text', 'usefulness', or 'sources'), sources [], text, textApproved, updatedAt, usefullness, _id}
  onRemoveStance,
  onChange,
  shouldAutoFocus,
  possibleSources,
  onCopyStance,
  scrollToOnActive,
  style
}) => {
  const ref = useRef(null);
  const isOnScreen = useOnScreen(ref);
  const [ anchorEl, setAnchorEl ] = useState(null);

  const openMoreMenu = (e) => {
    setAnchorEl(e.currentTarget);
  }

  // useEffect(() => {
  //   if(value._id === '66be75244886d5050cd636e4') {
  //     console.log(`rendered update at ${(new Date()).toISOString()}`)
  //   }
  // }, [ value.text ])

  const [ quoteFocused, setQuoteFocused ] = useState(false);

  useEffect(() => {
    if(active && ref?.current && !isOnScreen) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [active]);

  useEffect(() => {
    if(value.hasError && ref?.current && !isOnScreen) {
      onFocus(null, value)
    }
  }, [ value])

  const onChangeStar = (starValue, reasonForOneStar) => {
    let usefulness = (starValue - 1) / 4;
    let otherUpdates = {};
    if(starValue === 1 && reasonForOneStar) {
      if(reasonForOneStar === 'useless') {
        otherUpdates.redundant = false;
      } else if (reasonForOneStar === 'redundant') {
        otherUpdates.redundant = true;
      }
    } else {
      otherUpdates.redundant = false;
    }
    onChange({ ...value, usefulness, ...otherUpdates, hasError: false });
  }

  const onChangeSecondaryIssues = (e) => {
    const issuesSecondary = e.target.value;
    onChange({ ...value, issuesSecondary, hasError: false });
  }

  const issueOptionsRemainining = useMemo(() => {
    return issueOptions.filter((issue) => {
      return ((value.issuesSecondary || []).includes(issue.key) || issue.key === issueKey) ? false : true;
    });
  }, [ issueOptions, value.issuesSecondary, issueKey ])

  return (
    <Wrapper onClick={(e) => onFocus(e)} active={active ? 1 : 0} style={style} ref={ref}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <div style={{ 'display': 'flex', gap: '0px', alignItems: 'center' }}>
          <IssueDropdown value={issueKey} options={issueOptions} onChange={(issueKeyNew) => onReassignIssue(issueKeyNew)}/>
          <div style={{ width: '1px', height: '18px', backgroundColor: '#000000', margin: '0 8px', opacity: 0.4}} />
          <InputListCondensed
            name='secondaryIssues'
            onChange={onChangeSecondaryIssues}
            value={value.issuesSecondary || []}
            addComponent={(onAdd) => 
              <IssueDropdown
                value={null}
                placeholder={'Add more'}
                options={issueOptionsRemainining} 
                onChange={(issueKeyNew) => onAdd(issueKeyNew)}
              />
            }
            labelForItem={key => issueOptions.find((issue) => issue.key === key)?.title}
          />
        </div>
        <div className='spacer' style={{ width: '20px', minWidth: '20px', flex: 1, height: '1px'}} />
        {
          isFromProfiler
          ? <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', gap: '2px'}}>
              {
                active && false && 
                <Typography variant='body1' style={{ fontSize: '10px', color: '#555', marginTop: '-8px'}}>Usefulness</Typography>
              }
              <StarRanker 
                value={typeof(value?.usefulness) === 'undefined' 
                  ? undefined 
                  : ((value.usefulness * 4) + 1) 
                } 
                detailMenuOnOneStar={true}
                onChange={onChangeStar} 
              />
              {
                value.usefulness === 0 &&
                <Typography variant='body1' style={{ fontSize: '10px', color: '#555' }}><b>Reason:</b> {value.redundant ? `Redundant` : `Irrelevant/nonsense`}</Typography>
              }
            </div>
          : <Button variant='text' size='small' onClick={onRemoveStance} startIcon={ <RemoveIcon style={{ marginTop: '-4px'}}/>}>Remove</Button>
        }
        {
          isFromProfiler &&
          <IconButton size='small' style={{ marginLeft: '12px', marginTop: '2px' }} onClick={openMoreMenu}>
            <MoreVertIcon fontSize='small' style={{ opacity: 0.8 }} />
          </IconButton>
        }
      </div>
      <div style={{ display: 'flex'}}>
        <div style={{ width: '4px', minWidth: '4px', borderRadius: '4px', backgroundColor: '#000000', marginRight: '8px', alignSelf: 'stretch', ...(value?.hasError === 'text' ? { backgroundColor: '#FF0000' } : {}) }} />
        <TextField
          style={{ flex: 1,  backgroundColor: '#00000000' }}
          inputProps={{ style: { fontFamily: 'Lato', fontWeight: 700, fontSize: '18px', lineHeight: 1.25,  backgroundColor: '#00000000' } }}
          InputProps={{ disableUnderline: true, style: { backgroundColor: '#00000000' }}}
          multiline={true}
          placeholder={'Stance summary...'}
          value={value?.text || ''}
          onFocus={(e) => onFocus(e)}
          autoFocus={shouldAutoFocus}
          onChange={(e) => {
            onChange({ ...value, text: e.target.value, hasError: false })
          }}
        />
      </div>
      {
        active && isFromProfiler &&
        <div>
          <Typography
            variant='body2'
            style={{ fontSize: '13px', fontFamily: 'Lato', fontWeight: 400, color: '#333333' }}
          >
            From <a href={value?.sources[0]?.url} target='_blank' rel='noreferrer'>website</a>: "{value.directQuote}"
          </Typography>
        </div>
      }
      {
        active && !isFromProfiler &&
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'stretch', }}>
          <Typography variant='body2'>Direct text from source:</Typography>
          <TextField
            onFocus={() => setQuoteFocused(true)}
            onBlur={() => setQuoteFocused(false)}
            style={{ flex: 1,  backgroundColor: '#00000000', marginTop: '-8px' }}
            inputProps={{ style: { fontFamily: 'Lato', fontWeight: 500, fontSize: '14px', lineHeight: 1,  backgroundColor: '#00000000' } }}
            InputProps={{ disableUnderline: quoteFocused ? false : true, style: { backgroundColor: '#00000000' }}}
            multiline={true}
            placeholder={'Copy and past the direct text from the source here...'}
            value={quoteFocused 
              ? (value?.directQuote || '')
              // add quotes around it
              : (value.directQuote ? `"${value.directQuote}"` : '')
            }
            onChange={(e) => onChange({ ...value, directQuote: e.target.value, hasError: false })}
          />
        </div>
      }
      {
        active &&
        <SourceWrapper haserror={value?.hasError === 'sources' ? 1 : 0}>
          <Typography variant='body2' style={{ }}>Sources</Typography>
          <InputListCondensed
            value={value.sources}
            onChange={(e) => onChange({ ...value, sources: e.target.value, hasError: false })}
            labelForItem={(item) => {
              if(item.sourceType === 'website') return 'Candidate website';
              if(item.sourceType === 'questionnaire') return item.title || 'Questionnaire';
              return item?.title;
            }}
            addComponent={onAdd => <AddSource onAdd={onAdd} possibleSources={possibleSources} sourcesAlreadyUsed={value.sources}/>}
          />
        </SourceWrapper>
      }
      {
        !active &&
        <SourceWrapper>
          <SourceList sources={value.sources} />
        </SourceWrapper>
      }
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem style={{ padding: '0px 16px' }} onClick={() => {
          onCopyStance(value)
          setAnchorEl(null)
        }}>
          <ListItemIcon>
            <ContentCopy fontSize="small" />
          </ListItemIcon>
          <ListItemText>Copy stance</ListItemText>
        </MenuItem>
      </Menu>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  padding: 12px 16px;
  left: -16px;
  position: relative;
  width: calc(100% + 32px);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
  transition: transform 0.15s ease;

  ${({ active }) => active ? `
    background-color: #C3CEEC;
    transform: scale(1.05);
  ` : `
    opacity: 0.8;
  `}
`;

const SourceWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  gap: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;

  ${({ haserror }) => haserror ? `
    border: 1px solid #FF0000;
    border-radius: 8px;
  ` : ''}
`;

function useOnScreen(ref) {

  const [isIntersecting, setIntersecting] = useState(false)

  const observer = useMemo(() => new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting)
  ), [ref])


  useEffect(() => {
    observer.observe(ref.current)
    return () => observer.disconnect()
  }, [])

  return isIntersecting
}

export { EditStance };
