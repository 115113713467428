import React, { useState, useEffect, useMemo, useCallback } from "react";
import Typography from "@material-ui/core/Typography";
import { useFeathers, dataEditorUrlFromEntity } from "../../app/util";
import { useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import {
  Modal,
  TaskPage,
  CreateRaceModal,
  ChipMultiSelectMenu,
  RaceCandidatePreviewTile,
  CirclePhoto
} from "../../components";
import styled from "styled-components";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import { Skeleton } from "@material-ui/lab";
import { Link } from "@material-ui/core";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';


const ReportedErrors = () => {
  const feathers = useFeathers();
  const [task, setTask] = useState(null);
  const [error, setError] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ race, setRace ] = useState(null);
  const [ detailedData, setDetailedData ] = useState([]);
  const [ reportedError, setReportedError ] = useState(null)
  const [ errorNotes, setErrorNotes ] = useState('')
  const [ status, setStatus ] = useState(null)
  const [ holdingData, setHoldingData ] = useState(null);

  const completionData = useMemo(() => {
    return {
      status: status,
      notes: errorNotes
    }
  }, [status, errorNotes])

  const onCheckoutTask = async (task) => {
    setTask(task);
    setLoading(true);
    const reportedError = await feathers.getService('reported-errors').get(task.details.reportedError);
    setHoldingData({
      note: '',
      status: null,
      ...(task.holdingData || {})
    });
    setReportedError(reportedError);
    setError(false);
    setErrorNotes(reportedError.notes || '')
    setStatus(reportedError.status)
  }


  // get the race from the task, if applicable
  const loadDetailedData = async () => {
    // Identify key sources of data and load those
    let dataToLoad = [];
    if(reportedError?.service && reportedError?.serviceId) {
      dataToLoad.push({
        service: reportedError.service,
        id: reportedError.serviceId
      })
    }

    if(reportedError?.election) {
      dataToLoad.push({
        service: 'elections',
        id: reportedError.election
      })
    }

    if(reportedError?.details?.candidateId) {
      dataToLoad.push({
        service: 'candidates',
        id: reportedError.details.candidateId
      })
    }

    // dedeup the reportedError array
    dataToLoad = dataToLoad.filter((v,i,a)=>a.findIndex(t=>(t.service === v.service && t.id===v.id))===i)

    const loadedData = await Promise.all(dataToLoad.map(async (data) => {
      const service = data.service;
      const id = data.id;
      const result = await feathers.getService(service).get(id);
      const url = dataEditorUrlFromEntity(result, service);
      let title, renderItem;
      if(service === 'candidates') {
        title = 'Candidate';
        renderItem = () => <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <CirclePhoto src={result.photoPathFace} size='small'/>
          <Typography variant='body1'>{result.name}</Typography>
        </div>
      } else if(service === 'races') {
        title = 'Race';
        renderItem = () => <Typography variant='body1'>{result.longName}</Typography>
      } else if(service === 'elections') {
        title = 'Election';
        renderItem = () => <Typography variant='body1'>{result.name}</Typography>
      }

      return {
        service,
        id,
        result,
        url,
        title,
        renderItem
      }
    }));

    setDetailedData(loadedData);

    setLoading(false)
  }

  useEffect(() => {
    if (!feathers) return;
    if(!reportedError) {
      return
    }
    console.log('errord', reportedError)
    loadDetailedData();
  }, [feathers, reportedError])


  return (
    <Wrapper>
      <TaskPage
        taskType='reported-error'
        errorMessage={error?.message}
        childError={error}
        completionData={completionData}
        completeButtonLabel={'Resolve'}
        task={task}
        sidePaneConfig={[]}
        allowMultipleCheckedOutTasks={true}
        onCheckoutTask={onCheckoutTask}
      >
        {
          task &&
          <InnerWrapper>
            <Typography variant='h3'>Reported error</Typography>
            <ErrorDetailWrapper>
              {
                loading &&
                <>
                  <Skeleton variant='rect' width={80} height={30} />
                  <Skeleton variant='rect' width={80} height={30} />
                </>
              }
              {
                !loading &&
                <>
                  <Typography variant='body1' style={{ marginBottom: '24px'}}>
                    {reportedError?.description?.length > 0 
                      ? `"${reportedError?.description}"`
                      : 'No description provided.'
                    }
                  </Typography>
                  {
                    reportedError?.details && Object.keys(reportedError.details)?.length > 0 &&
                    Object.keys(reportedError.details).map(key => {
                      // take the camel case title and make it a human readable title
                      const title = key.replace(/([A-Z])/g, ' $1').replace(/^./, function(str){ return str.toUpperCase(); });
                      return (
                        <DetailRow>
                          <Typography variant='body1' style={{ fontWeight: 'bold'}}>{title}</Typography>
                          <Typography variant='body1'>{reportedError.details[key]}</Typography>
                        </DetailRow>
                      );
                    })
                  }
                  {
                    Boolean(reportedError?.reportedByUser || reportedError?.followUpEmail) &&
                    <DetailRow>
                      <Typography variant='body1' style={{ fontWeight: 'bold'}}>Reported by</Typography>
                      <Typography variant='body1'>{[reportedError?.reportedByUser?.name || reportedError?.reportedByUser?.firstName, reportedError?.followUpEmail || reportedError?.reportedByUser?.email].filter(Boolean).join(' - ')}</Typography>
                    </DetailRow>
                  }
                </>
              }
            </ErrorDetailWrapper>
            <div style={{ minHeight: '24px' }}>
            </div>
            <Typography variant='h3'>Related data</Typography>
            {
              loading &&
              <>
                <Skeleton variant='rect' width={80} height={30} />
                <Skeleton variant='rect' width={80} height={30} />
                <Skeleton variant='rect' width={80} height={30} />
              </>
            }
            {
              !loading &&
              detailedData.map((data, i) => {
                return (
                  <DetailRow key={i}>
                    <Typography variant='body1' style={{ fontWeight: 'bold'}}>{data.title}</Typography>
                    <Link href={data.url} target='_blank' style={{ display: 'flex', textDecoration: 'underline', alignItems: 'center' }}>
                      <Typography variant='body1'>{data.renderItem()}</Typography>
                      <OpenInNewIcon style={{ marginLeft: '4px', marginBottom: '3px', height: '18px', width: '18px' }}/>
                    </Link>
                  </DetailRow>
                );
              })
            }
            <Typography variant="h3" style={{ marginTop: '48px', marginBottom: '-8px' }}>Resolution</Typography>
            <FormControl component="fieldset">
              <HorizontalWrapper style={{ gap: '48px'}}>
                <Typography variant='body1' style={{ fontWeight: 'bold' }}>Status</Typography>
                <RadioGroup value={status} onChange={e => setStatus(e.target.value)} style={{ marginLeft: '24px'}}>
                  <HorizontalWrapper>
                    <FormControlLabel value="resolved" control={<Radio size='medium' />} label="Resolved" />
                    <FormControlLabel value="resolved-as-ignore" control={<Radio size='medium' />} label="Ignored as an error" />
                  </HorizontalWrapper>
                </RadioGroup>
              </HorizontalWrapper>
            </FormControl>
            <TextField
              size='medium'
              color='primary'
              multiline
              minRows={2}
              variant='outlined'
              value={errorNotes}
              onChange={e => setErrorNotes(e.target.value)}
              style={{ marginTop: '-8px', width: '100%', maxWidth: '800px' }}
              placeholder={'Notes about the error resolution. Ex: The candidate John Smith did indeed withdraw about one month ago.'}
            />
            {
              reportedError?.followUpEmail && reportedError.followUp &&
              <>
                <Typography variant="body1">Follow up with: {reportedError?.followUpEmail}</Typography>
              </>
            }
          </InnerWrapper>
        }
        {
          !task &&
          <Typography variant='body1'>Click a task on the left side pane to get started.</Typography>
        }
      </TaskPage>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 900px
`

const HorizontalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center; 
  gap: 12px;
  width: 100%;
`

const ErrorDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-radius: 4px;
  background-color: #D0DFE9;
`

const DetailRow = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
`


export default ReportedErrors;
