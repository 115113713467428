import React, { useEffect, useMemo, useState } from 'react';
import {colorsForTask, isPermitted, taskConfigurations, useFeathers} from '../../app/util';
import { useSelector } from 'react-redux';
import {
  useParams,
  Link
} from 'react-router-dom';

import {
  LoadingSpinner,
  DataDetailToolbar,
	TaskTile,
	TaskPreviewTile,
	CandidatePreviewTile,
	LineGraph,
	DateRangePicker
} from '../../components';
import styled from 'styled-components';
import moment from 'moment'
import Typography from '@material-ui/core/Typography';
import BarChartIcon from '@material-ui/icons/BarChart';
import TimeIcon from '@material-ui/icons/AccessTime';
import Numbers from '@material-ui/icons/Check'
import { Tooltip } from '@material-ui/core';


const ResearcherAnalytics = () => {
  const feathers = useFeathers();
  const user = useSelector(state => state.user);
  const { userId: researcherId } = useParams();

  const [ loading, setLoading ] = useState(false);
	const [ analytics, setAnalytics ] = useState(null);
	const [ startDate, setStartDate ] = useState(null);
	const [ endDate, setEndDate ] = useState(null);
	const [ researcher, setResearcher ] = useState(null);
	const [ tasks, setTasks ] = useState([])
	const [ candidateForTaskId, setCandidateForTaskId ] = useState({});
	const [ isAdmin, setIsAdmin ] = useState(false);

  const loadAnalytics = async () => {
		if(loading) return;
		setLoading(true)
    try {
      const analytics = await feathers.getService('researcher-analytics').find({
				query: {
					user: researcherId,
					group: ['date', 'taskType'],
					...(startDate ? { startDate: moment.utc(startDate).format('YYYY-MM-DD') } : {}),
					...(endDate ? { endDate: moment.utc(endDate).format('YYYY-MM-DD') } : {}),
				}
			});

			setAnalytics(analytics.data)
			setStartDate(analytics.startDate)
			setEndDate(analytics.endDate)

			const researcher = await feathers.getService('users').get(researcherId);
			setResearcher(researcher)

			const tasks = await feathers.getService('research-tasks').find({
				query: {
					status: 'complete',
					$includeCheckedOut: true,
					completedByUser: researcherId,
					$limit: 200,
					$sort: {
						completedAt: -1
					}
				}
			});

			setTasks(tasks.data)

			const candidateIds = tasks.data.map(task => task.details.candidate).filter(Boolean);

			const candidatesAll = await feathers.getService('candidates').find({
				query: {
					_id: candidateIds,
					$limit: candidateIds.length,
				}
			})

			const candidates = candidatesAll.data.reduce((acc, candidate) => {
				const taskIdForCandidate = tasks.data.find(task => task.details.candidate === candidate._id)?._id;
				if(taskIdForCandidate) {
					acc[taskIdForCandidate] = candidate;
				}
				return acc;
			}, {});
			setCandidateForTaskId(candidates)

    } catch(err) {
      console.error(err)
    } finally {
			setLoading(false)
		}
  }

  useEffect(() => {
    if(feathers) {
      loadAnalytics()
    }
  }, [ feathers ])

	useEffect(() => {
		if(!isPermitted(user, ['editor', 'admin', 'super-admin'])) {
			if(researcherId !== user._id) {
				window.location.href = '/home';
			}
		} else {
			setIsAdmin(true);
		}
	}, [])

	useEffect(() => {
		if(startDate && endDate) {
			loadAnalytics()
		}
	}, [ startDate, endDate ])

	const userAnalyticsByDate = useMemo(() => {
		const analyticsMapped = (analytics || []).reduce((acc, { _id, count, totalTimeToComplete, }) => {
			const completedAtDate = _id.completedAtDate;
			
			if(!acc[completedAtDate]) acc[completedAtDate] = { date: completedAtDate }
			
			const taskType = _id.type;

			acc[completedAtDate][taskType] = {
				count,
				totalTimeToComplete,
				averageTimePerTask: count > 0 ? totalTimeToComplete / count : null
			}

			return acc;
		}, {});

		const prepped = Object.values(analyticsMapped).map(({	date, ...taskTypes }) => {
			const totalCountPerDay = Object.values(taskTypes).reduce((acc, { count }) => acc + count, 0);
			const totalTimeToCompletePerDay = Object.values(taskTypes).reduce((acc, { totalTimeToComplete }) => acc + totalTimeToComplete, 0);
			const averageTimePerTaskPerDay = totalCountPerDay > 0 ? totalTimeToCompletePerDay / totalCountPerDay : 0;

			return {
				date,
				...taskTypes,
				'total': {
					count: totalCountPerDay,
					totalTimeToComplete: totalTimeToCompletePerDay,
					averageTimePerTask: averageTimePerTaskPerDay
				}
			}
		});

		return prepped;
	}, [ analytics ])

	if(loading) {
    return (
      <Wrapper>
          <LoadingSpinner scale={0.8}/>
      </Wrapper>
    )
  }

	const asLineGraphData = userAnalyticsByDate.map(({ date, ...rest }) => {
		return {
			date,
			value: rest['review-candidate']?.count
		}
	})

  return (
    <Wrapper>
      <DataDetailToolbar
        onBack={{ to: {
					pathname: `/team/analytics`,
				}}}
        navTree={[
          ...(isAdmin ? [
						{
							text: 'Team',
							to: {
								pathname: `/user-management`,
							}
						},
						{
							text: 'Team Analytics',
							to: {
								pathname: `/team/analytics`,
							}
						}
					] : [
						{
							text: 'Home',
							to: {
								pathname: `/home`,
							}
						}
					] ),
          { text: researcher ? (researcher?.name || researcher?.firstName || researcher?.email) : '--' }
        ]}
      />
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', marginBottom: '24px', marginTop: '12px' }}>
				<DateRangePicker startDate={startDate} endDate={endDate} onChangeStartDate={setStartDate} onChangeEndDate={setEndDate} />
			</div>
			<ChartWrapper>
				<LineGraph data={asLineGraphData} startDate={startDate} endDate={endDate}/>
			</ChartWrapper>
			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', marginTop: '32px' }}>
				<Typography style={{ }} variant={'h3'}>Completed tasks</Typography>
				{
					tasks?.length > 0 &&
					Object.keys(taskConfigurations).map(taskType => {
						const tasksForType = tasks.filter(task => task.type === taskType);
						if(tasksForType.length === 0) return null;

						return (
							<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', marginTop: '24px' }} key={taskType}>
								<Typography style={{ }} variant={'h5'}>{taskConfigurations[taskType].title}</Typography>
								<div style={{ display: 'flex', flexDirection: 'column', maxWidth: '800px', border: '1px solid #ccc', borderRadius: '5px', backgroundColor: '#FFFFFF', marginTop: '8px' }}>
									{
										tasksForType.filter(task => candidateForTaskId[task._id]).map((task, index) => {
											const candidateForTask = candidateForTaskId[task._id];
											return (
												<CandidatePreviewTile
													candidate={candidateForTask}
													key={candidateForTask._id}
													onClick={{ to: {
														pathname: `/elections/${candidateForTask?.election?.key || candidateForTask?.election}/races/${candidateForTask?.race?._id || candidateForTask?.race}/candidates/${candidateForTask._id}`
													}}}
												/>
											);
										})
									}
								</div>
							</div>
						)
					})
				}
				{
					tasks?.length === 0 &&
					<Typography style={{ }} variant={'body1'}>No tasks completed</Typography>
				}
			</div>
    </Wrapper>
  )
}

const formatTime = (timeInSec) => {
	const duration = moment.duration(timeInSec, 'seconds');
	const hours = duration.hours();
	const minutes = duration.minutes();
	const seconds = duration.seconds();

	return `${hours ? `${hours}h ` : ''}${minutes ? `${minutes}m ` : ''}${seconds}s`
}


const Wrapper = styled.div`
  width: calc(100% - 2 * 24px);
  min-height: calc(100% - 24px * 2);
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: stretch;
`;

const ChartWrapper = styled.div`
	width: calc(100% - 36px * 2);
	padding: 36px;
	background-color: #FFFFFF;
	border-radius: 8px;
	border: 1px solid #ccc;
`;

const numTaskTypes = 2;
const totalGroupings = 1 + numTaskTypes;



export default ResearcherAnalytics;
