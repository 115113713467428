import React, { Component, useEffect, useState } from 'react';
import { useFeathers, isPermitted } from '../../app/util';
import { useSelector } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {
  CTAButton,
  LoadingSpinner,
  BackButton,
  DataDetailToolbar,
  PreviewButton,
  DataField,
  TranslationPairDownload,
  LanguageSelectButton,
  InputListCondensed,
  ElectionResultsGraph,
  ElectionResultsSelectInput,
  FieldInlineEdit,
  AddMeasureSource,
 } from '../../components'
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import IconButton from '@material-ui/core/IconButton';
import PreviewIcon from '@material-ui/icons/Visibility';
import MeasureAsOnBallot from '../../components/MeasureAsOnBallot';
import MeasureBreakdown from '../../components/MeasureBreakdown';
import { ReactComponent as GreenCheck } from '../../images/green-check.svg';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';


const MeasureDetail = () => {
  const feathers = useFeathers();
  const user = useSelector(state => state.user);
  const { id, key } = useParams();
  const history = useHistory();
  const electionData = useSelector(state => state?.elections?.byKey[key])
  const resultsVisible = (electionData?.status || '').includes('result')
  const [ deleteModalOpen, setDeleteModalOpen ] = useState(false);
  const deletePermission = isPermitted(user, ['super-admin', 'editor'])

  /* handle data loading */
  const [ activeLanguage, setActiveLanguage ] = useState('en');
  const [ measureData, setMeasureData ] = useState(null);
  useEffect(() => {
    if(feathers) loadMeasureData(activeLanguage)
  }, [ feathers, activeLanguage ])
  const loadMeasureData = async (ln) => {
    try {
      const res = await feathers.getService('measures').get(id, {
        query: { ln }
      });
      console.log(res)
      setMeasureData(res);
    } catch(err) {
      console.error(err)
    }
  }

  /* handle the data editing */
  const editPermission = isPermitted(user, ['super-admin', 'admin', 'editor', 'researcher', 'reviewer', 'publisher']) && activeLanguage === 'en';
  const [ editing, setEditing ] = useState(false)
  const [ formInputs, setFormInputs ] = useState(null);
  const [ saving, setSaving ] = useState(false);
  const disabled = saving;
  const beginEdit = () => {
    const {
      title,
      descriptionShort,
      ballotText,
      actNumbers,
      whatItMeans,
      commentaryAvailable = false,
      supportersSay,
      opponentsSay,
      sources
    } = measureData;
    setFormInputs({
      title,
      descriptionShort,
      ballotText,
      actNumbers,
      whatItMeans,
      commentaryAvailable,
      supportersSay,
      opponentsSay,
      sources
    })
    setEditing(true)
  }

  const saveEdits = async () => {
    if(saving) return;
    setSaving(true);

    try {
      const res = await feathers.getService('measures').patch(id, formInputs)
      console.log(res)
      loadMeasureData(activeLanguage)
      setEditing(false)
    } catch(err) {
      console.log(err)
    } finally {
      setSaving(false)
    }
  }

  const onChange = (e) => {
    const target = e.target;
    const { name, value } = target;
    const update = {};
    update[name] = value;
    setFormInputs({
      ...formInputs,
      ...update
    })
  }
  /* Handle deleting */
  const deleteMeasure = async () => {
    setPublishing(true);
    try {
      const res = await feathers.getService('measures').remove(id)
      history.push(`/elections/${key}/measures`)
    } catch (err) {
      console.error(`Could not delete measure`, err)
    }
    setPublishing(false)
  }

  /* Handle publishing (changing of coverage status ) */
  const [ publishing, setPublishing ] = useState(false);
  const publishPermission = isPermitted(user, ['super-admin', 'editor']);
  const publishable = publishPermission && measureData?.coverageStatus === 'future-coverage';
  const publish = async () => {
    if(!publishable || publishing || !measureData) return;
    setPublishing(true);

    try {
      const update = await feathers.getService('measures').patch(measureData._id, {
        coverageStatus: 'coverage'
      })
    } catch(err) {
      console.log('Error', err)
      setPublishing(false)
    } finally {
      loadMeasureData()
    }
  }

  if(!measureData) {
    return (
      <Wrapper>
        <Section style={{ marginBottom: '20px' }}>
          <BackButton onClick={{ to: `/elections/${key}/measures` }} />
        </Section>
        <EmptyStateWrapper>
          <LoadingSpinner />
        </EmptyStateWrapper>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <DataDetailToolbar
        onBack={{ to: `/elections/${key}/measures` }}
        title={measureData.title}
        navTree={[
          {
            text: electionData?.name || 'Election',
            to: {
              pathname: `/elections/${key}`,
            }
          },
          {
            text: 'Measures',
            to: {
              pathname: `/elections/${key}/measures`
            }
          },
          { text: measureData.title ?? '--' }
        ]}
        actionButtonsComponent={
          editing
          ?
            <ButtonContainer>
              <Button variant='contained' color='secondary' onClick={saveEdits} style={{ color: '#FFFFFF' }}>
                Save
              </Button>
            </ButtonContainer>
          :
            <ButtonContainer>
              <LanguageSelectButton
                style={{ marginRight: '4px' }}
                selected={activeLanguage}
                onSelect={setActiveLanguage}
              />
              <TranslationPairDownload serviceName={'measures'} objectId={measureData?._id}/>
              <IconButton
                data-tip='Preview'
                style={{
                  marginLeft: '2px'
                }}
                href={`https://www.branch.vote/measures/${id}?staging=true`}
                target='_blank'
              >
                <PreviewIcon/>
              </IconButton>
              {
                deletePermission &&
                <IconButton data-tip='Delete measure' onClick={() => setDeleteModalOpen(true)}>
                  <DeleteIcon/>
                </IconButton>
              }
              <IconButton data-tip='Edit Draft' onClick={beginEdit}>
                <EditIcon/>
              </IconButton>
              {
                publishable &&
                <CTAButton
                  onClick={publish}
                  value={publishing ? 'Publishing...' : 'Publish'}
                  disabled={publishing}
                  mode='pill'
                  style={{
                    height: '24px',
                    paddingTop: '10px',
                    marginLeft: '6px'
                  }}
                  data-tip='Publish Draft'
                />
              }
            </ButtonContainer>
        }
      />
      {
        editing &&
        <EditingWrapper>
          <FieldGroup>
            <Typography variant='body1'>
              Title
            </Typography>
            <TextField
              disabled={disabled}
              variant='outlined'
              name='title'
              value={formInputs?.title}
              onChange={onChange}
              size='small'
            />
          </FieldGroup>
          <FieldGroup>
            <Typography variant='body1'>
              Short description
            </Typography>
            <TextField
              disabled={disabled}
              variant='outlined'
              name='descriptionShort'
              value={formInputs?.descriptionShort}
              onChange={onChange}
              size='small'
              placeholder='5 to 15 words about the referendum'
            />
          </FieldGroup>
          <FieldGroup>
            <Typography variant='body1'>
              Act numbers
            </Typography>
            <TextField
              disabled={disabled}
              variant='outlined'
              name='actNumbers'
              value={formInputs?.actNumbers}
              onChange={onChange}
              size='small'
              placeholder='The act numbers for the referendum (optional)'
            />
          </FieldGroup>
          <FieldGroup>
            <Typography variant='body1'>
              How it appears on the ballot
            </Typography>
            <TextField
              disabled={disabled}
              variant='outlined'
              name='ballotText'
              value={formInputs?.ballotText}
              onChange={onChange}
              multiline
              minRows={4}
              placeholder={`The exact text of how this appears on the ballot`}
            />
          </FieldGroup>
          <FieldGroup>
            <Typography variant='body1'>
              What it means
            </Typography>
            <TextField
              disabled={disabled}
              variant='outlined'
              name='whatItMeans'
              value={formInputs?.whatItMeans}
              onChange={onChange}
              multiline
              minRows={4}
              placeholder={`The Branch breakdown of what the referendum means`}
            />
            <div style={{ paddingTop: '6px'}}>
              <InputListCondensed
                disabled={disabled}
                name={`sources`}
                value={formInputs?.sources}
                onChange={onChange}
                labelForItem={(item) => {
                  if(item.sourceType === 'website') return 'Candidate website';
                  if(item.sourceType === 'questionnaire') return item.title || 'Questionnaire';
                  return item?.title;
                }}
                addComponent={onAdd => <AddMeasureSource onAdd={onAdd}/>}
              />
            </div>
            
          </FieldGroup>
          <FieldGroup>
            <Typography variant='body1'>
              What a "yes" vote means
            </Typography>
            <TextField
              disabled={disabled}
              variant='outlined'
              name='supportersSay'
              value={formInputs?.supportersSay}
              onChange={onChange}
              multiline
              minRows={4}
              placeholder={`2 to 5 bullet points on what a "yes" vote would mean`}
            />
          </FieldGroup>
          <FieldGroup>
            <Typography variant='body1'>
              What a "no" vote means
            </Typography>
            <TextField
              disabled={disabled}
              variant='outlined'
              name='opponentsSay'
              value={formInputs?.opponentsSay}
              onChange={onChange}
              multiline
              minRows={4}
              placeholder={`2 to 5 bullet points on what a "no" vote would mean`}
            />
          </FieldGroup>
        </EditingWrapper>
      }
      {
        !editing &&
        <div style={{ display: 'flex', flexDirection: 'row', }}>
          <StaticWrapper>
            <div style={{ padding: '20px' }}>
              <Typography variant='h4'>
                Short description
              </Typography>
              <Typography variant='body1'>
                {measureData?.descriptionShort}
              </Typography>
            </div>
            <MeasureAsOnBallot {...measureData} />
            <MeasureBreakdown 
              whatItMeans={measureData?.whatItMeans}
              supportersSay={measureData?.supportersSay}
              opponentsSay={measureData?.opponentsSay}
              sources={measureData?.sources}
            />
          </StaticWrapper>
          {
            resultsVisible &&
            <div style={{ margin: '36px 24px' }}>
              <Typography variant='h2'>Election results</Typography>
              {
                measureData?.result &&
                <ElectionResultsGraph
                  result={measureData.result}
                  detailed={true}
                  notifications={false}
                />
              }
              {
                editPermission &&
                <>
                  <Typography variant='h4' style={{ marginTop: '24px'}}>Election results links</Typography>
                  <Result option='Yes' measureId={id} result={measureData?.result} onSave={loadMeasureData}/>
                  <Result option='No' measureId={id} result={measureData?.result} onSave={loadMeasureData}/>
                </>
              }
            </div>
          }
        </div>
      }
      <Dialog
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete the measure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            If you delete the measure after the election has been published, it could have negative consequences for voters.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteModalOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              setDeleteModalOpen(false)
              deleteMeasure()
            }}
            color="primary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <ReactTooltip place='left' effect='solid' type='light'/>
    </Wrapper>
  )
}

const Result = ({ option, measureId, result, onSave }) => {
  const feathers = useFeathers()
  const autoSources = ((result?.raw || {})[option.toLowerCase()] || {}).autoSources || [];
  const [ editing, setEditing ] = useState(false);
  const [ clarityInput, setClarityInput ] = useState(false);
  const [ refreshing, setRefreshing ] = useState(false);
  const [ saving, setSaving ] = useState(false);

  const save = async () => {
    setSaving(true);

    const res = await feathers.getService('election-results').patch(
      measureId,
      {
        clarityId: clarityInput?.clarityLookup?.clarityId,
        option: option.toLowerCase()
      },
      {
        query: { $measure: true }
      }
    )

    setSaving(false)
    onSave()
    setClarityInput(null)
    setEditing(false)
  }

  const refresh = async () => {
    setRefreshing(true)
    const res = await feathers.getService('election-results').patch(
      measureId,
      {
        $refresh: true
      },
      {
        query: { $measure: true }
      }
    )
    setRefreshing(false)
    onSave()
  }

  if(editing) {
    return (
      <FieldInlineEdit
        inputComponent={
          <ElectionResultsSelectInput
            onChange={(e) => setClarityInput(e.target.value)}
            value={clarityInput}
            measureId={measureId}
            option={option.toLowerCase()}
            style={{ width: 'calc(100% - 15px)' }}
          />
        }
        loading={saving}
        style={{ width: 'calc(250px)', marginTop: '16px'}}
        onCancel={() => setEditing(false)}
        onSave={save}
        label={`${option} results`}
      />
    );
  }

  return (
    <DataField
      title={`${option} results`}
      onEdit={() => setEditing(true)}
      style={{ marginTop: '16px', width: '250px'}}
    >
      {
        autoSources.length > 0
        ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <GreenCheck data-tip={`Measure (${option}) has been linked to Clarity election results system.`}/>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', flex: 1, marginLeft: '8px' }}>
              <Typography variant='subtitle2' style={{ fontSize: '12px' }}>
                Last updated {moment.utc(autoSources[0].updatedAt).fromNow()} · {` `}
                {
                  refreshing
                  ? `Refreshing...`
                  : <u style={{ cursor: 'pointer', fontSize: '12px' }} onClick={() => refresh()}>Refresh</u>
                }
              </Typography>
            </div>
          </div>
        ) : (
          <Typography variant='body1' style={{ fontSize: '14px' }}>No election results linked.</Typography>
        )
      }
    </DataField>
  );
}

const Wrapper = styled.div`
  width: calc(100% - 24px - 36px);
  display: flex;
  flex-direction: column;
  padding: 36px 36px 36px 24px;
  align-items: stretch;
`;

const EditingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
`

const StaticWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 36px;
  align-items: stretch;
  max-width: 600px;
  background-color: #FFFFFF;
  border-radius: 16px;
  border: solid 1px #DDDDDD;
`

const Section = styled.div`
  margin: 0 0 48px;
  width: 100%;
  display: flex;
  h1 {
    font-size: 24px;
    margin: 0 0 0;
  }
`
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: -10px;

  div {
    margin: 0 0 0 0;
  }
`

const DataRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
`

const FieldGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`

const EmptyStateWrapper = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    ${({ theme }) => theme.font.normal};
    opacity: 0.8;
    font-size: 16px;
  }
`;

export default MeasureDetail;
