import React, { useEffect } from 'react';
import styled from 'styled-components';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ReactTooltip from 'react-tooltip';

const RedundantFlag = ({
  value,      // number from 1 to 5, or null
  onChange,
  style,
  className,
  dataTip
}) => {

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [ dataTip ])
  
  return (
    <Wrapper
      style={style}
      className={className}
      data-tip={dataTip}
    > 
      {
        Boolean(value)
        ? <FileCopyIcon onClick={() => onChange(false)} />
        : <FileCopyOutlinedIcon onClick={() => onChange(true)} />
      }
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
  cursor: pointer;
`

export { RedundantFlag };