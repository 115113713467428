import React, { useEffect, useMemo, useState } from 'react';
import {
  EditBioField,
  EditIssueMemoized,
  PhotoSelectFromMulti,
  CirclePhoto,
  FieldInlineEdit,
  DataField,
  PhotoUploadInput
} from '../index';
import styled from 'styled-components'
import { TextField, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { useFeathers, usePrevious } from '../../app/util';

/* A general purpose component for editing a candidate's profile, to work with the new stances model in issues.
  *  allows editing of photos, bios, and issues.
  * Links (references) handled seperately.
*/

// Call this function to convert the draft object to the format that the server expects
const mapDraftToServerInputs = (draft) => {
  return {
    name: draft.name,
    ...(draft?.photoPathFace ? { photoPathFace: draft.photoPathFace } : {}),
    issues: draft.issues.map((issue) => {
      return {
        key: issue.key,
        missingData: issue.missingData,
        stances: issue.stances.map((stance) => {
          // remove all fields that the user is not allowed to edit directly
          const { directQuote, copiedStance, autoSummary, editsMade, updatedAt, createdAt, _id, hasError, ...stanceRest} = stance;
          const isAutoSummary = (autoSummary?.isAutoSummary || Boolean(autoSummary?.text));
          let fieldsToUpdate;
          if(isAutoSummary && copiedStance) {
            // essentially allow everything to carry over
            fieldsToUpdate = {
              directQuote,
              autoSummary
            }
          } else if(isAutoSummary && !copiedStance) {
            // dont allow anything to be edited except the stanceRest fields
            fieldsToUpdate = {}
          } else if(!isAutoSummary) {
            // allow the user to edit the directQuote field
            fieldsToUpdate = {
              directQuote
            }
          }

          return {
            ...stanceRest,
            _id: _id.startsWith('NEW-') ? 'NEW' : _id,
            textApproved: true,
            ...fieldsToUpdate
          };
        })
      }
    }),
    bioPersonal: draft?.missingData?.bioPersonal ? '' : draft.bioPersonal,
    bioPolitical: draft?.missingData?.bioPolitical ? '' : draft.bioPolitical,
    bioProfessional: draft?.missingData?.bioProfessional ? '' : draft.bioProfessional,
    references: draft.references,
    missingData: draft.missingData ?? {
      'bioPersonal': undefined,
      'bioPolitical': undefined,
      'bioProfessional': undefined
    },
    bioPersonalSources: draft.bioPersonalSources,
    bioPoliticalSources: draft.bioPoliticalSources,
    bioProfessionalSources: draft.bioProfessionalSources,
  }
}

// Call this function to update the draft object after an error is returned from the server
const newDraftAfterError = (draft, error) => {
  if(error?.data?.issueKey && typeof error?.data?.stanceIndex === 'number') {
    const {
      issueKey,
      stanceIndex,
      field
    } = error.data;
    const issue = draft.issues.find(iss => iss.key === issueKey);
    const stanceId = issue ? issue.stances?.[stanceIndex]?._id : null;

    return {
      ...draft,
      issues: draft.issues.map(iss => {
        if(iss.key === issueKey) {
          return {
            ...iss,
            stances: iss.stances.map((s) => {
              if(s._id === stanceId) {
                return {
                  ...s,
                  hasError: field
                }
              }
              return s;
            })
          }
        }
        return iss;
      })
    }
  }

  return draft;
}

const EditCandidateProfile = ({
  race,
  candidateId,
  electionKey,
  photoOptions,
  disabled,
  value,                          // specifically corresponds to the candidate staging draft object
  onChange,
  onChangeIssue
}) => {
  const feathers = useFeathers();
  const [ activeStanceId, setActiveStanceId ] = useState(null);
  const [ detailedIssueData, setDetailedIssueData ] = useState({});
  const [ editingName, setEditingName ] = useState(false);

  const hasWebsite = useMemo(() => {
    return value?.references?.categories?.find(r => r.type === 'website')?.sources?.length > 0;
  }, [ value.references ])

  const issuesCovered = useMemo(() => {
    return (value?.issues || []).map((iss) => iss.key)
  }, [])

  const loadIssuesCovered = async () => {
    // do a get request against the issues service to get detailed data
    const loading = issuesCovered.reduce((acc, iss) => {
      acc[iss] = { loading: true }
      return acc;
    }, {})
    setDetailedIssueData(d => ({
      ...loading,
      ...d,
    }))

    try {
      const details = await feathers.getService('issues').find({
        query: {
          key: issuesCovered,
          $limit: issuesCovered.length
        }
      })

      const byKey = details.data.reduce((acc, iss) => {
        acc[iss.key] = iss;
        return acc;
      }, {})
      setDetailedIssueData(d => ({
        ...d,
        ...byKey
      }))
    } catch(err) {
      console.log(err)
      const res = issuesCovered.reduce((acc, iss) => {
        acc[iss] = { error: true }
        return acc;
      }, {})

      setDetailedIssueData(d => ({
        ...d,
        ...res
      }))
    }
  }

  useEffect(() => {
    if(feathers && issuesCovered?.length > 0) loadIssuesCovered();
  }, [ feathers, issuesCovered ])

  const possibleSources = useMemo(() => {
    // a list of possible sources available to the editor
    return (value?.references?.categories || []).map(cat => {
      return (cat.sources || []).map(source => {
        let displayAs;
        if(cat.type === 'website') displayAs = 'Candidate website';
        else if(cat.type === 'questionnaire') displayAs = source.title || 'Branch questionnaire';
        else if(source.title) displayAs = source.title;

        return {
          displayAs,
          value: source
        }
      })
    }).flat()
  }, [ value.references ])

  const issueOptions = useMemo(() => {
    if(!value.issues) return [];
    return value.issues.map((issue) => ({
      key: issue.key,
      title: issue.title,
      subtopics: detailedIssueData[issue.key]?.subtopics
    }))
  }, [ detailedIssueData ]);

  return (
    <Wrapper>
      <div style={{ display: 'flex', alignItems: 'center', gap: '16px'}}>
        <PhotoUploadInput
          style={{
            margin: '0 0 16px',
            alignSelf: 'center',
            maxWidth: '700px'
          }}
          value={value.photoPathFace}
          onChange={(v) => onChange({ ...value, photoPathFace: v })}
        />
        <div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
          {
            editingName
            ? <FieldInlineEdit
                inputComponent={
                  <TextField
                    value={value?.name}
                    name={'name'}
                    onChange={(e) => onChange({
                      ...value,
                      name: e.target.value
                    })}
                    style={{ width: 'calc(100% - 15px)' }}
                  />
                }
                onCancel={() => setEditingName(false)}
                onSave={() => setEditingName(false)}
              />
            :
              <DataField onEdit={() => setEditingName(true)}>
                <Typography variant='h3'>{value?.name}</Typography>
              </DataField>
          }
          <Typography variant='body1'>
            Running for {race?.officeName} in {race?.district?.longName} in {race?.election?.state?.name}.
          </Typography>
          <Link to={`/elections/${electionKey}/races/${race?._id}/candidates/${candidateId}`} target='_blank'>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
              <Typography variant='body2'>Full page</Typography>
              <OpenInNewIcon style={{ width: '15px', height: '15px' }}/>
            </div>
          </Link>
        </div>
      </div>
      { (photoOptions || []).length > 0 && // todo: enable this
        <div style={{ margin: '0 24px 24px' }}>
          <PhotoSelectFromMulti
            size={value?.photoPathFace ? 'small' : 'medium'}
            photoOptions={photoOptions}
            onSelect={(e) => onChange({
                ...value,
                photoPathFace: e
              })
            }
          />
        </div>
      }
      {['bioPolitical', 'bioProfessional', 'bioPersonal'].map((bioField) => (
        <EditBioField
          hasWebsite={hasWebsite}
          key={bioField}
          title={namedField[bioField]}
          value={{
            text: value?.[bioField],
            missingData: value?.missingData?.[`${bioField}`],
            sources: value?.[`${bioField}Sources`]
          }}
          possibleSources={possibleSources}
          onChange={(e) => {
            onChange({
              ...value,
              [bioField]: e.text,
              missingData: {
                ...value.missingData,
                [`${bioField}`]: e.missingData
              },
              [`${bioField}Sources`]: e.sources
            })
          }}
          disabled={disabled}
        />
      ))}
      <HorizontalSeperator />
      {(value.issues || []).map((issue, issueIdx, arr) => (
        <React.Fragment key={issue?.key}>
          <EditIssueMemoized
            hasWebsite={hasWebsite}
            detailedIssue={detailedIssueData[issue.key]}
            key={issue?.key}
            issueName={issue.title}
            value={issue}
            enableScrollToActive={true}
            possibleSources={possibleSources}
            disabled={disabled}
            issueOptions={issueOptions}
            activeStanceId={activeStanceId}
            onFocusStance={(e, newStanceIndex) => {
              setActiveStanceId(newStanceIndex)
            }}
            onReassignStance={(newIssue, stance) => {
              // Take the stance and assign it to the new category

              // First, we modify the stance issuesSecondary field to remove the current issue or new issue
              const stanceNew = {
                ...stance,
                issuesSecondary: (stance.issuesSecondary || []).filter((issueKey) => ((issueKey === newIssue) || (issueKey === stance.issue)) ? false : true)
              }

              const issueNewIndex = value.issues.findIndex((i) => i.key === newIssue);
              if(issueNewIndex < 0) throw new Error(`No issue with key ${newIssue} found`);
              const newIssues = value.issues.map((issue, i) => {
                if(i === issueIdx) {
                  // return this issue without the stance at stanceIdx
                  return {
                    ...issue,
                    stances: issue.stances.filter((s) => s._id !== stanceNew._id)
                  }
                } else if(i === issueNewIndex) {
                  return {
                    ...issue,
                    stances: [].concat(issue.stances, stanceNew)
                  }
                } else {
                  return issue;
                }
              })
              setActiveStanceId(stanceNew._id)
              onChange({
                ...value,
                issues: newIssues
              })
            }}
            onChange={(newIssue) => onChangeIssue(newIssue, issue.key)}
          />
          {issueIdx !== arr.length && <HorizontalSeperator />}
        </React.Fragment>
      ))}
    </Wrapper>
  )
}


const HorizontalSeperator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
`



const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 24px;
  max-width: 600px;
  padding-left: 36px;
`
const namedField = {
  'bioPolitical': 'Political background',
  'bioProfessional': 'Professional background',
  'bioPersonal': 'Personal background'
}

export { EditCandidateProfile, mapDraftToServerInputs, newDraftAfterError };